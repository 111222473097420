import {Popup} from "@mobiscroll/react";
import {useCallback, useEffect, useMemo, useState} from "react";
import AsyncSelect from "../../../../ComponentPackage/Selects/AsyncSelect";
import Field from "../../../../ComponentPackage/Inputs/Field";

const LocumFilter = ({setLocum, isOpen, setOpen, locum}) => {

    const [locumValue, setLocumValue] = useState(locum)

    const onApply = useCallback(() => {
        setLocum(locumValue?.id ?? null)
        setOpen(false)
    }, [locumValue])

    const onClear = useCallback(() => {
        setLocum(null)
        setOpen(false)
    }, [])

    //rest modal on close
    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    useEffect(() => {

        if (locum?.id != locumValue?.id)
            setLocumValue(locum)
    }, [locum])

    // popup options
    const popupButtons = useMemo(() => {

        return ['cancel', {
            handler: () => {
                onClear();
            }, text: `Clear`, cssClass: `mbsc-popup-button-close`
        }, {
            handler: () => {
                onApply();
            }, text: `Apply`, cssClass: `mbsc-popup-button-primary`
        }]

    }, [locum, locumValue]);

    return <Popup
        themeVariant='light'
        display="center"
        fullScreen={false}
        contentPadding={true}
        headerText='Locum Filter'
        buttons={popupButtons}
        scrollLock={false}
        isOpen={isOpen}
        onClose={onClose}
        cssClass="locum-filter-modal"
    >
        <div className='locums w-full float-left d-block'>
            <div className="float-left w-full block modal-splitters">
                <Field label="Locum" name="locum">
                    <AsyncSelect allowNullSearch={true} valueKey="id" labelKey="name"
                                 onChange={(name, value, option) => setLocumValue(option)}
                                 value={locum}
                                 routeName="api.locums.index"
                                 labelGenerate={(locum) => `${locum.first_name} ${locum.last_name}`}
                    />
                </Field>
            </div>
        </div>


    </Popup>
}

export {LocumFilter as default}
