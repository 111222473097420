import { useState, useCallback } from "react";
import {Inertia} from "@inertiajs/inertia";
import qs from "qs";

const setQueryStringWithoutPageReload = (qsValue, only = []) => {
    const new_url = window.location.protocol + "//" + window.location.host + window.location.pathname + qsValue;

    // Inertia.reload({ only: ['users'] })

    Inertia.visit(new_url, {
        only: only,
        preserveState: true,
        preserveScroll: true
    });
};

const setQueryStringValue = (key, value, queryString = undefined) => {
    const values = qs.parse(queryString || window.location.search.split('?')[1]);
    const newQsValue = qs.stringify({ ...values, [key]: value });

    setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue = (key, queryString = undefined) => {
    const values = qs.parse(queryString || window.location.search.split('?')[1]);
    return values[key];
};

export default (key, initialValue) => {
    const [value, setValue] = useState(getQueryStringValue(key) || initialValue);

    const onSetValue = useCallback(newValue => {
        setValue(newValue);
        setQueryStringValue(key, newValue);
    }, [key]);

    return [value, onSetValue];
};
