import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Inertia} from "@inertiajs/inertia";
import {Popup} from "@mobiscroll/react";
import route from "ziggy-js/src/js";
import Datepicker from "../../../../../ComponentPackage/Inputs/Datepicker";
import RateInput from "../../../../../Components/Inputs/RateInput";
import {rateToObj} from "../../Components/LocumRequest";
import Label from "../../../../../ComponentPackage/Inputs/Label";
import Field from "../../../../../ComponentPackage/Inputs/Field";
import moment from "moment";
import util from "util";

const Negotiation = ({isOpen, setOpen, setNegotiation, negotiation, locumRequest, disabled = false}) => {

    const [errors, setErrors] = useState()
    const [processing, setProcessing] = useState(false)

    const rateRef = useRef()

    const [rate, setRate] = useState()
    const [start, setStart] = useState()
    const [finish, setFinish] = useState()
    const [defaultNegotiation, setDefaultNegotiation] = useState({})

    useEffect(() => {
        const start = moment(negotiation?.start, 'HH:mm:ss').format('HH:mm')
        const finish = moment(negotiation?.finish, 'HH:mm:ss').format('HH:mm')
        const rate = negotiation?.rate ? rateToObj(negotiation.rate) : null

        setStart(start)
        setFinish(finish)
        setRate(rate)

        setDefaultNegotiation({start, finish, rate})

        if (rateRef?.current && rate) {
            rateRef.current.setValue(rate, 'select-option')
        }

    }, [negotiation])

    const canCounter = useMemo(() =>
            JSON.stringify(defaultNegotiation) != JSON.stringify({start, finish, rate}),
        [defaultNegotiation, rate, start, finish])

    //rest modal on close
    const onClose = useCallback(() => {
        setNegotiation(null)
        setStart(null)
        setFinish(null)
        setRate(null)

        setProcessing(false)
        setOpen(false);
    }, []);

    //handle response
    const handleResponse = useCallback((type) => {

        if (canCounter)
            return true;

        Inertia.patch(
            route('store.locumRequests.negotiations.respond', [locumRequest?.id, negotiation?.id]),
            {type: type},
            {
                onError: errors => {
                    setErrors(errors)
                    setProcessing(false)
                },
                onSuccess: e => onClose(),
                onBefore: e => setProcessing(true),
            }
        )

    }, [negotiation, canCounter]);

    //handle response
    const handleCounter = useCallback(() => {

        if (!canCounter)
            return true;

        Inertia.patch(
            route('store.locumRequests.negotiations.counter', [locumRequest?.id, negotiation?.id]),
            {...{start, finish}, ...{'rate': rate.value}},
            {
                onError: errors => {
                    setErrors(errors)
                    setProcessing(false)
                },
                onSuccess: e => onClose(),
                onBefore: e => setProcessing(true),
            }
        )

    }, [negotiation, canCounter]);

    // popup options
    const popupButtons = useMemo(() => {

        return ['cancel',
            (negotiation?.previous || locumRequest?.locum_id) ? null : {
                handler: () => {
                    handleResponse('accepted');
                },
                text: `Accept`,
                cssClass: `mbsc-popup-button-primary ${processing || canCounter ? 'disabled' : ''}`
            },
            (negotiation?.previous || locumRequest?.locum_id) ? null : {
                handler: () => {
                    handleResponse('rejected');
                },
                text: `Reject`,
                cssClass: `mbsc-popup-button-primary ${processing || canCounter ? 'disabled' : ''}`
            },
            (negotiation?.previous || locumRequest?.locum_id) ? null : {
                handler: () => {
                    handleCounter();
                },
                text: `Counter`,
                cssClass: `mbsc-popup-button-primary ${processing || !canCounter ? 'disabled' : ''}`
            },
        ].filter(btn => btn)

    }, [locumRequest, negotiation, rate, start, finish, processing, canCounter]);


    const locumRequestValues = useMemo(() => {
        return {
            rate: locumRequest?.rate,
            start: moment(locumRequest?.start, 'HH:mm:ss').format('HH:mm'),
            finish: moment(locumRequest?.finish, 'HH:mm:ss').format('HH:mm'),
        }

    }, [locumRequest])

    return (<>
        <Popup
            themeVariant='light'
            display="center"
            fullScreen={false}
            contentPadding={true}
            disabled={processing}
            headerText='View Negotiation'
            buttons={popupButtons}
            scrollLock={false}
            isOpen={isOpen}
            cancelText='Close'
            onClose={onClose}
            cssClass="negotiation-modal"
        >
            <div className="grid grid-cols-3 gap-3 negotiation-meta">
                <div className="col-span-3">
                    <h4>Locums' Offer</h4>
                </div>
                <div className="col-span-1">
                    <span>Start:</span><br/> {moment(negotiation?.previous?.data?.start ?? negotiation?.start, 'HH:mm:ss').format('HH:mm')}
                </div>
                <div className="col-span-1">
                    <span>Finish:</span><br/>  {moment(negotiation?.previous?.data?.finish ?? negotiation?.finish, 'HH:mm:ss').format('HH:mm')}
                </div>
                <div className="col-span-1">
                    <span>Rate:</span><br/>  £{negotiation?.previous?.data?.rate ?? negotiation?.rate}
                </div>
				
				<div className="col-span-3">
                    <h4>Counter Offer</h4>
                </div>
				
				<div className="col-span-3 align-cols">
					<Field label='Start'>
                        <Datepicker type='timegrid' name='start' value={start}
                                    disabled={disabled}
                                    onChange={(name, value) => setStart(value)} />
                    </Field>
                    {!locumRequest?.locum_id && <small
                        className={`${start != locumRequestValues.start ? 'text-red-500' : ''}`}>Original: {moment(locumRequest.start, 'HH:mm:ss').format('H:mm')}</small>}
				</div>
				
				<div className="col-span-3 align-cols">
					<Field label='Finish'>
                        <Datepicker type='timegrid' name='finish' value={finish}
                                    disabled={disabled}
                                    onChange={(name, value) => setFinish(value)} />
                    </Field>
                    {!locumRequest?.locum_id && <small
                        className={`${finish != locumRequestValues.finish ? 'text-red-500' : ''}`}>Original: {moment(locumRequest.finish, 'HH:mm:ss').format('H:mm')}</small>}
				</div>
				
				<div className="col-span-3 align-cols">
					 <Field label='Rate'>
                        <RateInput innerRef={rateRef} disabled={disabled} onChange={(name, value, option) => setRate(option)} value={rate} />
                    </Field>
                    {!locumRequest?.locum_id && <small className={`${rate?.value != locumRequestValues.rate ? 'text-red-500' : ''}`}>Original:
                        £{locumRequest.rate}</small>}
				</div>
            </div>
           

        </Popup>
    </>)
}

export {Negotiation as default}
