import { NumericFormat } from "react-number-format";
import React from "react";
import Error from "../../ComponentPackage/Inputs/Error";

const NumberMask = ({name, value, error, format = null, placeholder, mask, manualValue,
                        allOptions = false,
                        onChange, className, type, unformattedValue = true,
                        additionalOptions = {}, disabled}) => {

    const handleChange = (e) => {
        if (allOptions) {
            onChange(name, e)
        } else {
            onChange(name, unformattedValue ? e.value : e.formattedValue)
        }
    }

    const classes = (error && error.length > 0) ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' :
        'border-gray-300 focus:border-indigo-300 focus:ring-indigo-200';

    let options = {}

    switch (type) {
        case 'monetary':
            options = {
                thousandsGroupStyle: "thousand",
                prefix: "£",
                decimalSeparator: ".",
                displayType: "input",
                type:"text",
                thousandSeparator: true,
                allowNegative: false,
                decimalScale: 2,
                fixedDecimalScale: true
            }
            break
        case 'date':
            options = {
                format:"##/##/####",
                placeholder:"DD/MM/YYYY",
                mask:['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']
            }
            unformattedValue = false
            break
        case 'time':
            options = {
                format:"##:##",
                placeholder:"00:00",
            }
            unformattedValue = false
            break
        case 'positive':
            options = {
                allowNegative:false,
                decimalScale:0,
            }
            break
        case 'percent':
            options = {...options, ...{
                suffix: ' %'
            }}
        case 'minutes':
            options = {...options, ...{
                format:"##",
                suffix: ' minutes',
                allowNegative: false,
            }}
        case '2dp':
            options = {...options, ...{
                allowNegative:false,
                decimalScale: 2,
            }}
            break


    }

    options = {...options, ...additionalOptions}

    if (manualValue) {
        options.value = manualValue
    }

    return (
        <>
            <NumericFormat
                format={format} placeholder={placeholder} mask={mask}
                defaultValue={value}
                name={name} onValueChange={handleChange}
                disabled={disabled}
                {...options}
                className={
                    classes +
                    ` form-input ` +
                    className
                }
            />
            {
                error && <Error message={error} />
            }
        </>
    )
}

export default NumberMask
