import Authenticated from "../Layouts/Authenticated";
import PageTitle from "../../../ComponentPackage/PageTitle";
import {Inertia} from "@inertiajs/inertia";
import {useMemo} from "react";

const Index = ({storeBillingUrl}) => {
    const icons = useMemo(() => {
        const iconClassNames = 'w-6 h-6 float-left mr-4'
        return {
            'leave_review' : <img src="/images/new-icons/review.png"/>,
            'report_problem': <img src="/images/new-icons/problem.png"/>,
            'contact_us': <img src="/images/new-icons/contact.png"/>,
            'manage_billing': <img src="/images/new-icons/manage-billing.png"/>,
            'terms_conditions': <img src="/images/new-icons/terms-conditions.png"/>,
            'privacy_policy': <img src="/images/new-icons/privacy-policy.png"/>
        }
    }, [])

    const navItems = useMemo(() => [
        {name: 'Leave review', route: route('store.settings.review.create'), icon: icons.leave_review},
        {name: 'Report problem', route: route('store.settings.report-problem.create'), icon: icons.report_problem},
        {name: 'Contact us', route: route('store.settings.contact-us.create'), icon: icons.contact_us},
        (storeBillingUrl ? {name: 'Manage billing', route: storeBillingUrl, icon: icons.manage_billing} : null),
        {name: 'Terms & conditions', route: route('store.settings.index'), icon: icons.terms_conditions},
        {name: 'Cookie policy', route: route('store.settings.policies.cookie'), icon: icons.privacy_policy},
        {name: 'Privacy policy', route: route('store.settings.policies.privacy'), icon: icons.privacy_policy}
    ].filter(item => item), [icons])


    return (<ul role="list" className="panel-list float-left w-full d-block h-auto">
        {navItems.map((item) => (
		
			 <li key={item.name}
                className="bg-white panel-inner w-full flex title relative justify-start items-center content-center flex-wrap shadow mb-2 transition"
                onClick={e => Inertia.visit(item.route)}
            >
                <div className='dashboard-icon'>{item.icon}</div>
                <div className='dashboard-text font-bold float-left'>{item.name}</div>
                {item.number && <span className='number ml-1'>{item.number}</span>}
				
				<i class="fal fa-long-arrow-right ml-auto"></i>
            </li>
            
        ))}
    </ul>)
}

Index.layout = page => <Authenticated
        children={page}
        header={<PageTitle title='Settings' />}
        auth={page.props.auth}
    />

export {Index as default}
