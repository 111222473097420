import React from "react";

const Error = ({message}) => {
    //@todo error scroll
    return (
        <>
            <p className="mt-1 text-xs text-red-600 input-error">{message}</p>
        </>
    );
}

export default Error
