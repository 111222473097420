import {useCallback, useEffect, useMemo, useState} from "react";
import {objMap} from "../../../../utilities";
import {Inertia} from "@inertiajs/inertia";
import {Popup} from "@mobiscroll/react";
import Modal from "../../../../ComponentPackage/Modals/Modal";
import Button from "../../../../ComponentPackage/Button";
import {ExclamationCircleIcon} from "@heroicons/react/24/outline";
import {Dialog} from "@headlessui/react";
import moment from "moment";

const RemoveLocumRequest = ({isOpen, setOpen, locumRequest}) => {

    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    const onDelete = useCallback(() => {

        Inertia.delete(route('store.locumRequests.destroy', locumRequest?.id), {onSuccess: e => onClose()})

    }, [locumRequest])

    const contentText = useMemo(() => {

        return `Are you sure you would like to delete the locum request on ${moment(locumRequest?.date).format('DD/MM/YYYY')}`

    }, [locumRequest]);

    const ConfirmButton = () => <Button style="primary" onClick={() => onDelete()}>
        Confirm
    </Button>

    const Content = () => <div className="sm:flex sm:items-start">
        <div
            className="modal-icon mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
        </div>
        <div className="text-left content pl-4">
            <Dialog.Title as="h3" className="float-left w-full mb-1 d-block modal-title">
                Delete Locum Request
            </Dialog.Title>
            <p className="float-left w-full d-block">
                {contentText}
            </p>
        </div>
    </div>

    return (<>
        <Modal setOpen={setOpen} open={isOpen} confirmButton={<ConfirmButton/>} children={<Content/>}/>
    </>)
}

export {RemoveLocumRequest as default}
