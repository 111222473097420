import Authenticated from "../Layouts/Authenticated";
import PageTitle from "../../../ComponentPackage/PageTitle";
import Tabs from "../../../ComponentPackage/Containers/Tabs";
import Negotiations from "./Tabs/Negotiations";
import Overview from "./Tabs/Overview";
import {Section} from "../../../ComponentPackage/Sections";

const Show = ({locumRequest, dayRate, tab = 'overview', negotiations}) => {

    console.log(negotiations)
    const tabs = [
        { label: 'Overview', href: `/locum-requests/${locumRequest.id}`, name: 'overview', Render: <Overview locumRequest={locumRequest} dayRate={dayRate} /> },
        { label: 'Negotiations', href: `/locum-requests/${locumRequest.id}/negotiations`, name: 'negotiations', Render: <Negotiations {...{negotiations, locumRequest}} /> },
    ];

    return (
        <>
            <Section >
                <Tabs tabs={tabs} activeTabName={tab ?? 'overview'} />
            </Section>
        </>
    )
}
Show.layout = page => (
    <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle title={`Locum Request`}/>}
    />

)

export {Show as default}
