import React from "react";
import {Inertia} from "@inertiajs/inertia";
import Confirmation from "../../../../ComponentPackage/Modals/Confirmation";

const ActivationButton = ({store}) => {

    const activationToggle = (storeId, type, setProcessing) => {
        Inertia.patch(route('admin.stores.deactivation', storeId), {
            type: type
        }, {
            onBefore: () => setProcessing(true)
        })
    }

    return (
        <>
            {!(!!store.is_deactivated) && <Confirmation message={'Confirm this store will be deactivated'} title={'Deactivate Store'}
                                              onConfirm={setProcessing => activationToggle(store.id, 'deactivate', setProcessing)}
                                              buttonStyle="danger"
                                              buttonText="Deactivate"></Confirmation>}

            {!!store.is_deactivated && <Confirmation message={'Confirm this store will be activated'} title={'Activate Store'}
                                                 onConfirm={setProcessing => activationToggle(store.id, 'activate', setProcessing)}
                                                 buttonStyle="positive"
                                                 buttonText="Activate"></Confirmation>}
        </>
    )
}

export default ActivationButton
