import Authenticated from "../Layouts/Authenticated";
import PageTitle from "../../../ComponentPackage/PageTitle";
import Profile from "../Stores/Tabs/Profile";
import UpcomingBookings from "../Stores/Tabs/UpcomingBookings";
import PreviousBookings from "../Stores/Tabs/PreviousBookings";
import CancelledBookings from "../Stores/Tabs/CancelledBookings";
import Tabs from "../../../ComponentPackage/Containers/Tabs";
import {Section} from "../../../ComponentPackage/Sections";

const Show = ({store, dayRates, tab, previousBookings, upcomingBookings, cancelledBookings}) => {

    const tabs = [
        { label: 'Profile', href: `/stores/${store.id}`, name: 'profile', Render: <Profile store={store} dayRates={dayRates} /> },
        { label: 'Upcoming Bookings', href: `/stores/${store.id}/upcoming-bookings`, name: 'upcoming-bookings', Render: <UpcomingBookings store={store} upcomingBookings={upcomingBookings} /> },
        { label: 'Previous Bookings', href: `/stores/${store.id}/previous-bookings`, name: 'previous-bookings', Render: <PreviousBookings store={store} previousBookings={previousBookings} /> },
        { label: 'Cancelled Bookings', href: `/stores/${store.id}/cancelled-bookings`, name: 'cancelled-bookings', Render: <CancelledBookings store={store} cancelledBookings={cancelledBookings} /> },
    ];

    return (
        <Section>
            <Tabs tabs={tabs} activeTabName={tab ?? 'profile'} />
        </Section>
    )
}

Show.layout = page => (
    <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle title={`Store Overview ${page.props.store?.name ? `(${page.props.store?.name})` : ''}`}/>}
    />

)

export {Show as default}
