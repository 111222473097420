import React from 'react';
import Authenticated from "../Layouts/Authenticated";

const Dashboard = ({auth: {user}}) => {

    return (
        <>
        </>
    );
}

function PageTitle(props) {
    return null;
}

Dashboard.layout = page => (
    <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle
            title="Dashboard"
        />}
    />
);

export default Dashboard
