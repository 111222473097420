
import React from "react";
import {Link} from "@inertiajs/inertia-react";
import {classNames} from "../../../utilities";

const NavigationItem = ({item}) => {

    //Modal
    if (item.custom) {
        return item.custom
    }

    //Link
    if (item.href) {
        return (
            <a
                key={item.name}
                href={item.href}
                className={classNames(
                    item.current
                        ? ''
                        : '',
                    item.classNames
                )}
            >
                
                <span className="icon"></span><span  className="title">{item.name}</span>
            </a>
        )
    }
}

export default NavigationItem
