import {Section} from "../../../../ComponentPackage/Sections";
import {Table} from "../../../../ComponentPackage/Tables";
import useQueryString from "../../../../Hooks/useQueryString";
import {storeBookingTable} from "./UpcomingBookings";

const PreviousBookings = ({previousBookings, store}) => {

    const [searchValue, setSearchValue] = useQueryString("store-search", '')

    return (
        <Section header={{
            title: "All Previous Bookings",
            onSearchChange: value => setSearchValue(value),
            searchValue: searchValue,
            searchPlaceholder: "Search Locum Requests",
        }}>
			<div className="float-left w-full block mt-5 h-auto">
            <Table data={previousBookings.data} meta={previousBookings.meta} config={storeBookingTable} />
			</div>
        </Section>
    );
};


export {PreviousBookings as default}
