import React, {useContext} from 'react';
import AuthContext from "../../Context/authContext";
import TablePaginationElement from "./TablePaginationElement";
import {Table} from "react-bootstrap";

const TableElement = ({ data = [], meta = {}, config = {}, additionalData = {} }) => {
    const { user, userCan } = useContext(AuthContext);

    return (
        <React.Fragment>
            <div className="w-full block float-left responsive-table">
                            <table className="w-full">
                                <thead className="">
                                <tr>
                                    {config.columns.map((column, index) => (
                                        <th
                                            key={index}
                                            scope="col"
                                            className=""
                                        >
                                            { column.title }
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody className="">
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        {config.columns.map((column, index) => (
                                            <td key={index} className="">
                                                { column.render(item, { user, userCan }, additionalData) }
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                    </div>

            { meta.pagination && <TablePaginationElement pagination={meta.pagination} /> }
        </React.Fragment>
    );
};

export default TableElement;
