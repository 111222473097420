import React from 'react';
import {Header} from "../../ComponentPackage/Sections/index";
import {classNames} from "../../utilities";

const SectionElement = ({ header, children, wrap = true, fluid = false, className = '' }) => {
    return (
        (
            wrap ?
                (
                    <div className={classNames('section-panel', className)}>
                        <div className={fluid ? '' : 'container-outer'}>
                            <div className=" float-left w-full h-auto">
                                <div className=" content panel-inner">
                                    { header && <Header {...header} /> }

                                    { children }
                                </div>
                            </div>
                        </div>
                    </div>

                )
                :
                (
                    <React.Fragment>
                        { header && <Header {...header}/> }

                        { children }
                    </React.Fragment>
                )
        )

    );
};

export default SectionElement;
