import React, {useEffect} from 'react';
import {Head, Link, useForm} from '@inertiajs/inertia-react';
import Guest from "../Layouts/Guest";
import Label from "../../../ComponentPackage/Inputs/Label";
import Input from "../../../ComponentPackage/Inputs/Input";
import Checkbox from "../../../ComponentPackage/Inputs/Checkbox";
import Button from "../../../ComponentPackage/Button";
import Error from "../../../ComponentPackage/Inputs/Error";

function InputError(props) {
    return null;
}

export default function Login({status, canResetPassword}) {
    const {data, setData, post, processing, errors, reset} = useForm({
        email: '', password: ''
    });

    useEffect(() => {
        return () => {
            reset('password');
        };
    }, []);

    const onHandleChange = (event) => {
        setData(event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value);
    };

    const submit = (e) => {
        e.preventDefault();

        post(route('admin.login'));
    };

    return (<Guest>

			<h1 className="text-5xl float-left w-full title font-normal mb-4">Login</h1>

            <form className="w-full float-left block" onSubmit={submit}>
				<div className="grid grid-cols-1 gap-5">
					<div className="col-span-1">
						<Label forInput="email" value="Email" className="label title w-full h-auto block"/>

                        <Input
                            type="text"
                            name="email"
                            value={data.email}
                            error={errors.email}
                            className="mt-1 block w-full"
                            autoComplete="username"
                            isFocused={true}
                            onChange={onHandleChange}
                        />

                        <Error message={errors.email} className="mt-2"/>


					</div>

					<div className="col-span-1">
						<Label forInput="password" value="Password"  className="label title w-full h-auto block"/>

                           <Input
                                type="password"
                                name="password"
                                value={data.password}
                                className="mt-1 block w-full"
                                autoComplete="current-password"
                                onChange={onHandleChange}
                            />


                            <Error message={errors.password} className="mt-2"/>
					</div>

					<div className="col-span-1">
						{canResetPassword && (<Link
                                    href={route('password.request')}
                                    className="underline text-sm text-gray-600 hover:text-gray-900"
                                >
                                    Forgot your password?
                                </Link>)}

                            <Button className="mt-1 w-full" processing={processing}>
                                Log in
                            </Button>
					</div>


				</div>

            </form>


        </Guest>);
}
