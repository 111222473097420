import {createRef} from "react";
import {InertiaLink} from "@inertiajs/inertia-react";
import {Inertia} from "@inertiajs/inertia";

const classNames = (...classes) => classes.filter(Boolean).join(' ');

const Tabs = ({ tabs, activeTabName = null }) => {
    const select = createRef();

    const handleOnSelectChange = e => Inertia.visit(select.current.value);

    const activeTab = tabs.find(tab => tab.name === activeTabName);

    return (
        <div>
            <div className="hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    ref={select}
                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    onChange={handleOnSelectChange}
                    value={activeTab.href}
                >
                    {tabs.map((tab) => (
                        <option key={tab.name} value={tab.href}>{tab.label}</option>
                    ))}
                </select>
            </div>
            <div className="block">

                    <nav className="flex tabs title" aria-label="Tabs">
                        {tabs.map((tab) => (
                           <li>
						    <InertiaLink
                                key={tab.name}
                                href={tab.href}
                                className={classNames(
                                    tab.name === activeTabName
                                        ? 'active transition'
                                        : 'transition', tab.classNames
                                )}
                                aria-current={tab.name === activeTabName ? 'page' : undefined}
                            >
							<span className="icon-block transition"></span>
                                {tab.label}
                            </InertiaLink>
							</li>
                        ))}
                    </nav>

            </div>

            <div className=" mt-5 float-left w-full">
                { activeTab.Render }
            </div>
        </div>
    )
};

export default Tabs;
