import Authenticated from "../Layouts/Authenticated";
import PageTitle from "../../../ComponentPackage/PageTitle";
import {Section} from "../../../ComponentPackage/Sections";
import Field from "../../../ComponentPackage/Inputs/Field";
import {useCallback, useState} from "react";
import {Inertia} from "@inertiajs/inertia";
import Button from "../../../ComponentPackage/Button";

const ReportProblem = ({}) => {
    const [errors, setErrors] = useState()
    const [processing, setProcessing] = useState()

    const [title, setTitle] = useState()
    const [feedback, setFeedback] = useState()

    const onSave = useCallback(() => {
        Inertia.post(route('store.settings.report-problem.store'), {title, feedback}, {
            onError: errors => {
                setErrors(errors)
                setProcessing(false)
            },
            onSuccess: e => setProcessing(false),
            onBefore: e => setProcessing(true)
        })
    }, [title, feedback])

    return <>
		 <Section className="clear-both float-left w-full block">
			<div className="grid grid-cols-1 gap-5">
				<div className="col-span-1">
					 <Field label='Problem Title *' error={errors?.title}
                   onChange={(name, value) => setTitle(value)}
                   value={title}
            />
				</div>
				
				<div className="col-span-1">
					<Field label='Problem Details *' error={errors?.feedback} type='textarea'
                   onChange={(name, value) => setFeedback(value)} value={feedback}/>
				</div>
				
				<div className="col-span-1">
					<div className="float-right  mt-1">
						 <Button style="positive" processing={processing} onClick={onSave}>
                    Send Problem
                </Button>
					</div>
				</div>
			</div>
			
            

            

        </Section>
	
       
    </>
}

ReportProblem.layout = page => <Authenticated
    children={page}
    auth={page.props.auth}
    header={<PageTitle title='Report Problem'/>}
/>

export {ReportProblem as default}
