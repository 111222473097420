import Button from "../../../../ComponentPackage/Button";
import RequestDate from "./RequestDate";
import CalendarDatePicker from "./CalendarDatePicker";
import SendType from "./SendType";
import {Popup} from "@mobiscroll/react";
import {useCallback, useEffect, useMemo, useState} from "react";
import moment from "moment";
import Datepicker from "../../../../ComponentPackage/Inputs/Datepicker";

const DateFilter = ({setDate, isOpen, setOpen, date}) => {

    const [dateValue, setDateValue] = useState()

    const onApply = useCallback(() => {
        setDate(dateValue)
        setOpen(false)
    }, [dateValue])

    const onClear = useCallback(() => {
        setDate(null)
        setOpen(false)
    }, [])

    //rest modal on close
    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    useEffect(() => {
        if (date != dateValue) setDateValue(date)
    }, [date])

    // popup options
    const popupButtons = useMemo(() => {

        return ['cancel', {
            handler: () => {
                onClear();
            }, text: `Clear`, cssClass: `mbsc-popup-button-close`
        }, {
            handler: () => {
                onApply();
            }, text: `Apply`, cssClass: `mbsc-popup-button-primary`
        }]

    }, [date, dateValue]);

    return <Popup
        themeVariant='light'
        display="center"
        fullScreen={false}
        contentPadding={true}
        headerText='Date Filter'
        buttons={popupButtons}
        scrollLock={false}
        isOpen={isOpen}
        onClose={onClose}
        cssClass="date-filter-modal"
    >
        <div className='dates w-full float-left d-block'>
            <div className="float-left w-full block modal-splitters">
                <Datepicker type='date' name='date' value={dateValue}
                    // min={moment().toDate()}
                    // disabled={disabled}
                            customOptions={{display: 'inline'}}
                            onChange={(name, value) => setDateValue(value)}/>
            </div>
        </div>


    </Popup>
}

export {DateFilter as default}
