
import React from "react";
import {default as InputMaskComponent} from 'react-input-mask';

const InputMask = ({name, value,
                       error, placeholder,
                       mask, onChange, className, type,
                       unformattedValue = true,
                       options = {},
                       kind = 'custom'
}) => {

    let newOptions = {}



    switch (type) {
        case 'opl':
            newOptions = {
                mask: '01-99999 ***',
            }
            break;
        case 'dbs':
            newOptions = {
                mask: '999999999999',
            }
            break;
        case 'goc':
            newOptions = {
                mask: '01-99999',
            }
            break;
    }

    let mergedOptions = {...newOptions, ...options}

    return (
    <InputMaskComponent
            {...mergedOptions}
            value={value ?? ''}
            onChange={onChange}
    >
    </InputMaskComponent>
    )
}

export default InputMask
