import {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {ExclamationCircleIcon, XCircleIcon} from '@heroicons/react/24/outline'
import {Inertia} from "@inertiajs/inertia";
import Button from "../Button";


export default function DeleteConfirmation({message, title, onClose, onConfirm, route, deleteChild = "Delete", buttonClasses}) {

    const [open, setOpen] = useState(false)

    const [deleting, setDeleting] = useState(false);

    function closeModal() {
        setOpen(false)

        route && Inertia.delete(route, {
                preserveScroll: true,
                onBefore: visit => setDeleting(true),
                onFinish: visit => setDeleting(false),
            });

        onConfirm && onConfirm()

    }

    return (
        <>
            <Button style="danger" className={buttonClasses} type="button" onClick={() => setOpen((open) => !open)} processing={deleting}>
                {deleteChild}
            </Button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-50 overflow-hidden px-6 py-6 top-0 left-0 w-full h-full min-h-screen delete-modal" onClose={setOpen}>
                    <div
                        className="flex justify-center h-full float-left text-center items-center content-center w-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 modal-background transition-opacity"/>
                        </Transition.Child>


                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div
                                className="inline-block bg-white modal-content  px-6 py-6 text-left overflow-hidden shadow-xl transform transition-all">
                                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="bg-white close-modal"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XCircleIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    
                                    <div className="text-left content">
                                        <Dialog.Title as="h3" className="float-left w-full mb-1 d-block modal-title">
                                            {title}
                                        </Dialog.Title>
                                            <p className="float-left w-full d-block">
                                                {message}
                                            </p>

                                    </div>
                                </div>
                                <div className="mt-5 float-left w-full flex justify-between">

                                    <button
                                        type="button"
                                        className="btn w-auto px-6 title btn-success"
                                        onClick={() => setOpen(false)}
                                    >	
									<span>
                                        Cancel
										</span>
                                    </button>

									<button
                                        type="button"
                                        className="btn title w-auto px-6 btn-danger ml-auto"
                                        onClick={() => closeModal()}
                                    >	
									<span>
                                        Delete
										</span>
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )

}
