import {useCallback, useState} from "react";
import Field from "../../../../ComponentPackage/Inputs/Field";
import AsyncSelect from "../../../../ComponentPackage/Selects/AsyncSelect";
import AddressAutocomplete from "../../../../ComponentPackage/Address/AddressAutocomplete";
import Button from "../../../../ComponentPackage/Button";
import {Inertia} from "@inertiajs/inertia";


const Store = ({store, disabled = false}) => {

    const [errors, setErrors] = useState()
    const [processing, setProcessing] = useState()

    const otherStoreSystem = store?.systems?.find(system => system.name == 'Other')

    const [additionalInformation, setAdditionalInformation] = useState(store?.additional_information)
    const [parkingInformation, setParkingInformation] = useState(store?.parking_information)
    const [company, setCompany] = useState(store?.company)
    const [systems, setSystems] = useState(store?.systems?.map(system => ({...system, ...{value: system.id}})))
    const [otherSystem, setOtherSystem] = useState(otherStoreSystem?.pivot?.other)
    const [name, setName] = useState(store?.name)
    const [number, setNumber] = useState(store?.number)
    const [telephone, setTelephone] = useState(store?.telephone)
    const [contactName, setContactName] = useState(store?.contact_name)
    const [address, setAddress] = useState(store?.address)

    const onUpdate = useCallback(() => {
        Inertia.patch(route('store.profile.store.update'), {
            ...{
                    company_id: company?.id,
                    systems: systems?.map(system => system.value),
                    contact_name: contactName,
                    additional_information: additionalInformation,
                    parking_information: parkingInformation,
                    other_system: otherSystem
                },
            ...{name, number, telephone, address}
        }, {
            onError: errors => {
                setErrors(errors)
                setProcessing(false)
            }, onSuccess: e => setProcessing(false), onBefore: e => setProcessing(true)
        })
    }, [name, company, number, telephone, address, parkingInformation, additionalInformation, systems, otherSystem, contactName])

    return <>


        <div className="grid grid-cols-1 gap-5">
            <div className="col-span-1">
                <Field label="Company *" name="company" error={errors?.company}>
                    <AsyncSelect disabled={true} allowNullSearch={true} valueKey="id" labelKey="name" value={company}
                                 onChange={(name, value, option) => setCompany(option)}
                                 routeName="api.companies.index"/>
                </Field>
            </div>

            <div className="col-span-1">
                <Field disabled={true} label="Store Name *" name="name" value={name}
                       onChange={(name, value) => setName(value)}
                       error={errors?.store_name}/>
            </div>

            <div className="col-span-1">
                <Field disabled={true} label="Store Number *" name="number" value={number}
                       onChange={(name, value) => setNumber(value)}
                       error={errors?.store_number}/>
            </div>

            <div className="col-span-1">
                <Field disabled={disabled} label="Store Telephone *" name="telephone" value={telephone}
                       onChange={(name, value) => setTelephone(value)}
                       error={errors?.telephone}/>
            </div>


            <div className="col-span-1">
                <Field disabled={disabled} label="Store Contact Name *" name="contact_name" value={contactName}
                       onChange={(name, value) => setContactName(value)}
                       error={errors?.contact_name}/>
            </div>


            <div className="col-span-1">
                <Field disabled={disabled} label="System(s) *" name="systems" value={systems}
                       onChange={(name, value, option) => setSystems(option)}
                       error={errors?.systems}>
                    <AsyncSelect allowNullSearch={true} valueKey="id" labelKey="name"
                                 value={systems} onChange={(name, value, option) => setSystems(option)}
                                 routeName="api.systems.index" multiple={true}/>
                </Field>
            </div>

            {systems?.find(system => system.name == 'Other') && <div className="col-span-1">
                <Field disabled={disabled} label="Other System *" name="other_system" value={otherSystem}
                       onChange={(name, value) => setOtherSystem(value)}
                       error={errors?.other_system}/>
            </div>}

            <AddressAutocomplete disabled={true} setData={setAddress} errors={errors} address={address}/>

            <div className="col-span-1">
                <Field disabled={disabled} label="Additional Store Information" name="additional_information" value={additionalInformation}
                       onChange={(name, value) => setAdditionalInformation(value)} type='textarea'
                       error={errors?.additional_information}/>
            </div>

            <div className="col-span-1">
                <Field disabled={disabled} label="Parking Information" name="parking_information" value={parkingInformation}
                       onChange={(name, value) => setParkingInformation(value)} type='textarea'
                       error={errors?.parking_information}/>
            </div>


            {!disabled &&
                <div className="col-span-1">
                    <Button style="positive" className="float-right mt-5" disabled={disabled}
                            // processing={processing}
                            onClick={onUpdate}>
                        Update Store Info
                    </Button>
                </div>}


        </div>


    </>
}

export {Store as default}
