import {useCallback, useEffect, useMemo, useState} from "react";
import {Inertia} from "@inertiajs/inertia";
import {Popup} from "@mobiscroll/react";
import Field from "../../../../ComponentPackage/Inputs/Field";
import NumberMask from "../../../../ComponentPackage/Inputs/NumberMask";
import RateInput from "../../../../Components/Inputs/RateInput";
import {objMap} from "../../../../utilities";

const AddLocum = ({isOpen, setOpen, locum}) => {

    const [errors, setErrors] = useState()

    const [weekday, setWeekday] = useState()
    const [saturday, setSaturday] = useState()
    const [sunday, setSunday] = useState()

    useEffect(() => {
        setWeekday(null)
        setSaturday(null)
        setSunday(null)
    }, [locum])

    const onClose = useCallback(() => {
        setWeekday(null)
        setSaturday(null)
        setSunday(null)

        setOpen(false);
    }, []);

    const onSave = useCallback(() => {

        const data = {
            ...objMap({weekday, saturday, sunday}, day => day?.value),
            ...{locum_id: locum?.id}
        }

        Inertia.post(route('store.trustpool.store'), data, {onError: setErrors, onSuccess: e => onClose()})

    }, [weekday, saturday, sunday, locum])

    // popup options
    const popupButtons = useMemo(() => {

        return ['cancel', {
            handler: () => {
                onSave();
            },
            text: 'Save',
            cssClass: 'mbsc-popup-button-primary'
        }]

    }, [weekday, saturday, sunday]);

    // popup options
    const headerText = useMemo(() => {

        return `Add ${locum?.first_name} ${locum?.last_name} to Trustpool`

    }, [locum]);

    return (<>
        <Popup
            display="center"
            fullScreen={true}
            contentPadding={false}
            headerText={headerText}
            buttons={popupButtons}
            isOpen={isOpen}
            onClose={onClose}
            cssClass="employee-shifts-popup"
        >
			<div className="w-full block px-6">
            <div className="grid grid-cols-1 gap-5">
                <div className="col-span-1 align-cols">
                    <Field label="Weekday Rate" error={errors?.weekday}>
                        <RateInput onChange={(name, value, option) => setWeekday(option)} value={weekday} />
                    </Field>
                </div>
                <div className="col-span-1 align-cols">
                    <Field label="Saturday Rate" error={errors?.saturday}>
                        <RateInput onChange={(name, value, option) => setSaturday(option)} value={saturday} />
                    </Field>
                </div>
                <div className="col-span-1 align-cols">
                    <Field label="Sunday Rate" error={errors?.sunday}>
                        <RateInput onChange={(name, value, option) => setSunday(option)} value={sunday} />
                    </Field>
                </div>
            </div>
			</div>
        </Popup>
    </>)
}

export {AddLocum as default}
