import Authenticated from "../../Layouts/Authenticated";
import PageTitle from "../../../../ComponentPackage/PageTitle";
import {useState} from "react";
import NegotiationPill from "./Components/NegotiationPill";
import {Section} from "../../../../ComponentPackage/Sections";
import Negotiation from "./Components/Negotiation";
import Audit from "./Components/Audit";

const Index = ({negotiations, locumRequest}) => {

    const [isViewOption, setViewOpen] = useState(false)
    const [isAuditOpen, setAuditOpen] = useState(false)
    const [negotiation, setNegotiation] = useState({})

    return (<Section header={{title: "All Negotiations"}}>
        {negotiations?.data?.map(negotiation => <NegotiationPill key={negotiation.id} {...{locumRequest, setNegotiation, setViewOpen, negotiation, setAuditOpen}} />)}
        <Negotiation {...{locumRequest, negotiation, setNegotiation}} disabled={!!negotiation?.previous || !!locumRequest?.locum_id} isOpen={isViewOption} setOpen={setViewOpen} />
        <Audit {...{locumRequest, negotiation, setNegotiation}} isOpen={isAuditOpen} setOpen={setAuditOpen} />
    </Section>)
}

Index.layout = page => <Authenticated children={page} auth={page.props.auth} header={<PageTitle title='Negotiations'/>} />

export {Index as default}
