import Button from "../../../../ComponentPackage/Button";
import moment from "moment";
import {useCallback} from "react";


const CompletedPill = ({locumRequest, setLocumRequest, setViewModalOpen}) => {

    const openViewModal = useCallback(() => {
        setLocumRequest(locumRequest)
        setViewModalOpen(true)
    }, locumRequest)

    return (
	<div className="w-full flex justify-center items-start content-start flex-wrap relative full-width-split-inner">
	<div className="grid grid-cols-6 gap-3 relative locum-pill w-full">
		<div className="col-span-6">
			<div className="flex justify-start title align-center items-center float-left assigned-user">
				{!!locumRequest.is_trustpool && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 245">
<path d="m56,237 74-228 74,228L10,96h240"/>
</svg>}

                {locumRequest?.locum?.data?.first_name} {locumRequest?.locum?.data?.last_name}: {locumRequest?.locum?.data?.opl}
			</div>
		</div>

        <div className="col-span-3">


			<div className="w-full d-block locum-meta">
            <div className='w-full font-bold title'>{moment(locumRequest.date).format('ddd Do MMM YYYY')}</div>
            <div className='w-full'>{moment(locumRequest.start, 'HH:mm:ss').format('H:mm')} - {moment(locumRequest.finish, 'HH:mm:ss').format('H:mm')}</div>
			</div>
        </div>
        <div className="col-span-2">
			<div className="w-full d-block locum-meta">
            <div className="w-full font-bold">Rate</div>
            <div className="w-full">£{locumRequest.rate}</div>
			</div>
        </div>
        <div className="col-span-1">
			<div className="flex float-right">
				<div className="btn btn-sm btn-primary btn-icon " onClick={openViewModal}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M208 368.667V208c0-15.495-7.38-29.299-18.811-38.081C210.442 152.296 224 125.701 224 96c0-52.935-43.065-96-96-96S32 43.065 32 96c0 24.564 9.274 47.004 24.504 64H56c-26.467 0-48 21.533-48 48v48c0 23.742 17.327 43.514 40 47.333v65.333C25.327 372.486 8 392.258 8 416v48c0 26.467 21.533 48 48 48h144c26.467 0 48-21.533 48-48v-48c0-23.742-17.327-43.514-40-47.333zM128 32c35.346 0 64 28.654 64 64s-28.654 64-64 64-64-28.654-64-64 28.654-64 64-64zm88 432c0 8.837-7.163 16-16 16H56c-8.837 0-16-7.163-16-16v-48c0-8.837 7.163-16 16-16h24V272H56c-8.837 0-16-7.163-16-16v-48c0-8.837 7.163-16 16-16h104c8.837 0 16 7.163 16 16v192h24c8.837 0 16 7.163 16 16v48z"/></svg>
				</div>

			</div>
        </div>
    </div>
	</div>




	)
}

export {CompletedPill as default}
