import React, {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import Button from "../../ComponentPackage/Button";

const Modal = ({children, open, setOpen, confirmButton}) => {

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-50 overflow-hidden px-6 py-6 top-0 left-0 w-full h-full min-h-screen modal-outer delete-modal" onClose={() => setOpen(false)}>
                    <div className="flex justify-center h-full float-left text-center items-center content-center w-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 modal-background transition-opacity bg-slate-200 bg-opacity-75" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block bg-white modal-content  p-6 text-left overflow-hidden shadow-xl transform transition-all max-w-xl">
                                <div className="float-left w-full block">
                                    {children}
                                </div>
                                <div className="close-modal float-left w-full mt-6">
                                    <Button
                                        style="danger"

                                        onClick={() => setOpen(false)}
                                    >
                                        Close
                                    </Button>
                                    {confirmButton}
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default Modal
