import {useMemo} from "react";
import Select from "../../ComponentPackage/Inputs/Select";

const RateInput = ({innerRef, value, onChange, classNames, disabled = false }) => {

    const options = useMemo(() => {
        return [...new Array(199)]
            .map((each, index) => ({ label: `£${(index+1)*5}`, value: (index+1)*5 }))
    }, [])

    return <Select {...{options, value, disabled, onChange, innerRef}} className={classNames} />
}

export {RateInput as default}
