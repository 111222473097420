import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Inertia} from "@inertiajs/inertia";
import {Popup} from "@mobiscroll/react";
import route from "ziggy-js/src/js";
import Datepicker from "../../../../../ComponentPackage/Inputs/Datepicker";
import RateInput from "../../../../../Components/Inputs/RateInput";
import {rateToObj} from "../../Components/LocumRequest";
import Label from "../../../../../ComponentPackage/Inputs/Label";
import Field from "../../../../../ComponentPackage/Inputs/Field";
import moment from "moment";
import util from "util";
import AuditLog from "../../../../../ComponentPackage/Auditor/AuditLog";

const Audit = ({isOpen, setOpen, setNegotiation, negotiation, locumRequest}) => {


    //rest modal on close
    const onClose = useCallback(() => {
        setNegotiation(null)
        setOpen(false);
    }, []);

    // popup options
    const popupButtons = useMemo(() => {

        return ['cancel']

    }, [locumRequest, negotiation]);

    return (<>
        <Popup
            themeVariant='light'
            display="center"
            fullScreen={false}
            contentPadding={true}
            headerText='View Audit'
            buttons={popupButtons}
            scrollLock={false}
            isOpen={isOpen}
            cancelText='Close'
            onClose={onClose}
            cssClass="negotiation-audit-modal"
        >
            <div className="grid grid-cols-3 gap-4">
                <div className="col-span-3">
                    <AuditLog audits={negotiation?.audits?.data} />
                </div>
            </div>

        </Popup>
    </>)
}

export {Audit as default}
