import {useCallback} from "react";
import RateInput from "../../../../Components/Inputs/RateInput";

const LocumPill = ({locum, type = 'locum', setAction}) => {

    return <div className="w-full flex justify-center items-start content-start flex-wrap relative full-width-split-inner">
	<div className="grid grid-cols-6 gap-3 relative locum-pill w-full">
       
        <div className="col-span-3">
			<div className="flex w-full justify-start">
				<svg onClick={e => setAction(locum, locum.is_trustpool ? 'remove' : 'add')} xmlns="http://www.w3.org/2000/svg" fill={`${locum.is_trustpool ? 'gold' : 'none'}`} viewBox="0 0 260 245">
<path d="m56,237 74-228 74,228L10,96h240"/>
</svg>
				<div className="pl-2 locum-meta">
					<div className="w-full font-bold title">{locum.first_name} {locum.last_name}</div>
            <div className="w-full">{locum?.opl}</div>
				</div>
			</div>
		
            
        </div>
        <div className="col-span-1">
            {
                type == 'trustpool' && <>
					<div className="w-full d-block locum-meta">
                    <div className="w-full font-bold">W.Day</div>
                    <div className="w-full">£{locum.weekday}</div>
					</div>
                </>
            }
        </div>
        <div className="col-span-1">
            {
                type == 'trustpool' && <>
					<div className="w-full d-block locum-meta">
                    <div className="w-full font-bold">Sat</div>
                    <div className="w-full">£{locum.saturday}</div>
					</div>
                </>
            }
        </div>
        <div className="col-span-1">
            {
                type == 'trustpool' && <>
					<div className="w-full d-block locum-meta">
                    <div className="w-full font-bold">Sun</div>
                    <div className="w-full">£{locum.sunday}</div>
					</div>
                </>
            }
        </div>
    </div>
	</div>
}

export {LocumPill as default}
