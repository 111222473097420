const TypeIcon = ({setType, icon, type, active, label, disabled = false}) => {

    return (<>
        <div className={`grid place-items-center locum-request-type-container ${type} ${active ? 'active' : ''}`} onClick={e => !disabled ? setType(type) : null}>
            <div className={`locum-request-type-icon ${type}`}>
                {icon}
            </div>
            <span className={`locum-request-type-label ${type}`}>{label}</span>
        </div>
    </>)
}

export {TypeIcon as default}
