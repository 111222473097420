import React from 'react';

export default function Button({ type = 'submit', style = 'primary', className = '', processing, disabled = false, children, onClick }) {

    let classes = "";
    switch(style) {
        case 'primary':
            classes = "  btn-primary transition ";
            break;
        case 'positive':
            classes = "  btn-success transition ";
            break;
        case 'danger':
            classes = "  btn-danger transition  ";
            break;
        case 'super-admin':
            classes = " btn-alert transition "
            break;
        case 'white':
            classes = " btn-white transition"
            break;
        case 'secondary':
        default:
            classes = " btn-secondary transition ";
            break;
    }

    return (
        <button
            type={type}
            onClick={onClick}
            className={
                `title btn px-6 ${
                    processing || disabled && 'opacity-25'
                } ${classes} ${className}`
            }
            disabled={processing || disabled}
        >
            {
                processing &&
                <svg className="animate-spin -ml-1 mr-3 h-3 w-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                </svg>
            }

            <span>{children}</span>
        </button>
    );
}
