import {Popup} from "@mobiscroll/react";
import Field from "../../../../ComponentPackage/Inputs/Field";
import Datepicker from "../../../../ComponentPackage/Inputs/Datepicker";
import moment from "moment";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import axios from "axios";
import AuthContext from "../../../../Context/authContext";
import SearchBar from "../../../../Components/SearchBar";
import CustomTrustpoolPill from "./CustomTrustpoolPill";

const CustomTrustpool = ({isOpen, setOpen, onSuccess, trustpoolLocums, disabled = false}) => {

    const { user } = useContext(AuthContext);

    const [isLoading, setLoading] = useState(false)
    const [search, setSearch] = useState()
    const [selectedLocums, setSelectedLocums] = useState([])
    const [trustpool, setTrustpool] = useState([])

    useEffect(() => {
        axios.get(route('api.store.trustpool.index', {store: user.store_id, search: search}), {
            before: (xhr) => {
                setLoading(true)
            }
        }).then(response => {
            if ('data' in response && 'data' in response.data) {
                setTrustpool(response.data.data)
                setLoading(false)
            }
        }).catch(() => {
            console.log('hitting catch')
        })
    }, [search])

    const handleSave = useCallback(() => {
        setOpen(false)
        onSuccess(selectedLocums)
    }, [selectedLocums])

    useEffect(() => {
        setSelectedLocums(trustpoolLocums)
    }, [trustpoolLocums])


    // popup options
    const popupButtons = useMemo(() => {

        if (disabled){
            return [{
                handler: () => {
                    onClose();
                },
                text: `Close`,
                cssClass: `mbsc-popup-button-primary`
            }]
        }

        return ['cancel', {
            handler: () => {
                handleSave();
            },
            text: 'Add Locums',
            cssClass: 'mbsc-popup-button-primary'
        }]

    }, [selectedLocums]);

    const onClose = useCallback(() => {
        setSelectedLocums(trustpoolLocums)
        setOpen(false)
    }, [trustpoolLocums]);

    const handleSelection = useCallback((locum, active) => {

        const newSelectedLocums = [...selectedLocums]

        if (active) {
            setSelectedLocums(newSelectedLocums.filter(selectedLocum => selectedLocum.id != locum.id))
        } else {
            newSelectedLocums.push(locum)
            setSelectedLocums(newSelectedLocums)
        }

    }, [selectedLocums])

    return (<Popup
        themeVariant='light'
        display="center"
        contentPadding={true}
        headerText="Custom Trustpool"
        buttons={popupButtons}
        isOpen={isOpen}
        onClose={onClose}
        cssClass="locum-request-add-dates-modal"
    >
        <div className='add-dates w-full'>
			<div className="float-left w-full d-block mb-6">
            <SearchBar searchValue={search} setSearchValue={setSearch} name='search_locums' />
			</div>
            <div className="float-left w-full block modal-splitters">
                {trustpool.map(trustpoolLocum => <CustomTrustpoolPill disabled={disabled} locum={trustpoolLocum} active={!!selectedLocums.find(selectedLocum => trustpoolLocum.id == selectedLocum.id)} setAction={handleSelection} /> )}
            </div>
        </div>
    </Popup>)
}

export {CustomTrustpool as default}
