import {useEffect, useState} from "react";
import Field from "../../../../ComponentPackage/Inputs/Field";
import AddressAutocomplete from "../../../../ComponentPackage/Address/AddressAutocomplete";
import InputMask from "../../../../ComponentPackage/Inputs/InputMask";
import {useForm} from "@inertiajs/inertia-react";
import Button from "../../../../ComponentPackage/Button";
import Documents, {formatLocumDocuments} from "../Components/Documents";


const formatLocumProfile = (locum) => ({
    first_name: locum?.first_name,
    last_name: locum?.last_name,
    mobile: locum?.mobile,
    email: locum?.email,
    address: locum?.address,
})

const Profile = ({locum, documentTypes}) => {

    const {data, setData, patch, processing, errors} = useForm(formatLocumProfile(locum))

    function submit(e) {
        e.preventDefault();

        patch(route('admin.locums.update', [locum.id, 'profile']), {preserveScroll: true})
    }

    const [first_name, setFirstName] = useState(locum?.first_name)
    const [last_name, setLastName] = useState(locum?.last_name)
    const [mobile, setMobile] = useState(locum?.mobile)
    const [email, setEmail] = useState(locum?.email)
    const [address, setAddress] = useState(locum?.address)
    const [documents, setDocuments] = useState(formatLocumDocuments(documentTypes, locum))

    useEffect(() => {
        setData({
            first_name: first_name,
            last_name: last_name,
            mobile: mobile,
            email: email,
            address: address,
            documents: documents
        })
    }, [first_name, last_name, mobile, email, address, documents])

    return (
        <>
            <div className="grid grid-cols-6 gap-5">
                <div className="col-span-6 md:col-span-3">
                    <Field label="First Name *" name="first_name" value={first_name} onChange={(name, value) => setFirstName(value)}
                           error={errors.first_name} />
                </div>
                <div className="col-span-6 md:col-span-3">
                    <Field label="Last Name *" name="last_name" value={last_name} onChange={(name, value) => setLastName(value)}
                           error={errors.last_name} />
                </div>
                <div className="col-span-6 md:col-span-3">
                    <Field label="Mobile *" name="mobile" value={mobile} onChange={(name, value) => setMobile(value)}
                           error={errors.mobile}/>
                </div>
                <div className="col-span-6 md:col-span-3">
                    <Field label="Email *" name="email" value={email} onChange={(name, value) => setEmail(value)}
                           error={errors.email}/>
                </div>

				 <div className="col-span-6 md:col-span-6">
                <AddressAutocomplete setData={setAddress} errors={errors} address={address} />
				</div>

                <Documents documentTypes={documentTypes} setData={setDocuments} locum={locum} errors={errors}/>

                
                <div className="col-span-6 sm:col-span-6">
                    <div className="flex items-center justify-end  mt-1">
                        <Button style="positive" processing={processing} onClick={e => submit(e)}>
                            Update Profile
                        </Button>
                    </div>
                </div>


            </div>
        </>
    )
}

export {Profile as default}
