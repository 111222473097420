import RequestDate from "./RequestDate";
import {Popup} from "@mobiscroll/react";
import {useCallback, useEffect, useMemo, useState} from "react";
import Datepicker from "../../../../ComponentPackage/Inputs/Datepicker";
import moment from "moment";
import Field from "../../../../ComponentPackage/Inputs/Field";

const CalendarDatePicker = ({isOpen, setOpen, addDates, invalidDays}) => {

    const [dates, setDates] = useState([])

    const handleAddDates = useCallback(() => {

        const newDates = dates.split(', ')

        if (newDates.length)
            addDates(newDates)

        setDates(null)

        setOpen(false)

    }, [dates])

    // popup options
    const popupButtons = useMemo(() => {

        return ['cancel', {
            handler: () => {
                handleAddDates();
            },
            text: 'Add Date(s)',
            cssClass: 'mbsc-popup-button-primary'
        }]

    }, [dates]);

    const onClose = useCallback(() => {
        setDates(null)
        setOpen(false)
    }, []);

    return (<Popup
        themeVariant='light'
        display="center"
        fullScreen={false}
        contentPadding={true}
        // headerText={headerText}
        buttons={popupButtons}
        isOpen={isOpen}
        onClose={onClose}
        cssClass="locum-request-add-dates-modal"
    >
        <div className='float-left clear-both block mt-6 add-dates w-full'>
            <Field label='Select Locum Request Date(s)'>
                <Datepicker type='dates' name='date' value={dates}
                            min={moment().toDate()}
                            customOptions={{invalid: [
                                    {
                                        recurring: {
                                            repeat: 'weekly',
                                            weekDays: invalidDays
                                        }
                                    }
                                ]}}
                            onChange={(name, value) => setDates(value)} />
            </Field>
        </div>
    </Popup>)
}

export {CalendarDatePicker as default}
