
import PageTitle from "../../../ComponentPackage/PageTitle";
import Authenticated from "../Layouts/Authenticated";
import Form from "../../Admin/Stores/Form";
import {useEffect, useState} from "react";
import {useForm} from "@inertiajs/inertia-react";
import Button from "../../../ComponentPackage/Button";
import Profile, {formatDayRates, formatStore} from "../../Admin/Stores/Tabs/Profile";
import {Section} from "../../../ComponentPackage/Sections";
import Tabs from "../../../ComponentPackage/Containers/Tabs";
import UpcomingBookings from "../../Admin/Stores/Tabs/UpcomingBookings";
import PreviousBookings from "../../Admin/Stores/Tabs/PreviousBookings";
import CancelledBookings from "../../Admin/Stores/Tabs/CancelledBookings";
import Store from "./Tabs/Store";
import Clinic from "./Tabs/Clinic";

const Index = ({store, dayRates, tab = 'store'}) => {

    const tabs = [
        { label: 'Store Info', href: `/profile`, name: 'store', Render: <Store store={store}/> },
        { label: 'Clinic Info', href: `/profile/clinic`, name: 'clinic', Render: <Clinic {...{store}} dayRates={dayRates ? formatDayRates(dayRates) : []} /> },
    ];

    return (
        <Section header={{title: 'Profile'}}>
            <Tabs tabs={tabs} activeTabName={tab ?? 'store'} />
        </Section>
    )
}

Index.layout = page => (
    <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle title={`Store Overview ${page.props.store?.name ? `(${page.props.store?.name})` : ''}`}/>}
    />
)

export {Index as default}
