import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Inertia} from "@inertiajs/inertia";
import {Popup} from "@mobiscroll/react";
import RequestDate, {formatLocumRequest} from "./RequestDate";
import moment from "moment";
import CalendarDatePicker from "./CalendarDatePicker";
import Button from "../../../../ComponentPackage/Button";
import SendType from "./SendType";

const rateToObj = (rate) => {
    return {
        value: rate,
        label: `£${rate}`,
    }
}

const LocumRequest = ({isOpen, setOpen, setLocumRequest, locumRequest, store, disabled = false}) => {

    const [errors, setErrors] = useState()
    const [processing, setProcessing] = useState(false)

    const [isCalendarOpen, setCalendarOpen] = useState(false)

    const [dates, setDates] = useState([])
    const [sendType, setSendType] = useState('all')
    const [customPool, setCustomPool] = useState([])

    useEffect(() => {
        setDates(locumRequest ? [formatLocumRequest(locumRequest)] : [])

        setSendType(locumRequest?.send_type ?? 'all')
        setCustomPool(locumRequest?.send_type == 'custom_trustpool' ? locumRequest.trustpool?.data : [])

    }, [locumRequest])

    //When send type isnt custom trustpool, set to null
    useEffect(() => {

        if (sendType != 'custom_trustpool') {
            setCustomPool([])
        }

    }, [sendType])

    //rest modal on close
    const onClose = useCallback(() => {
        setDates([])
        setSendType('all')
        setCustomPool([])
        setLocumRequest(null)

        setProcessing(false)
        setOpen(false);
    }, []);

    const onSave = useCallback(() => {

        //Catch for many save clicks
        // if (processing)
        //     return true


        const data = {
            ...{dates},
            ...{send_type: sendType, custom_trustpool: customPool?.map(locum => locum.id)}
        }

        const options = {
            onError: errors => {
                setErrors(errors)
                setProcessing(false)
            },
            onSuccess: e => onClose(),
            onBefore: e => setProcessing(true),
        }

        if (locumRequest) {
            Inertia.patch(route('store.locumRequests.update', locumRequest.id), data, options)
        } else {
            Inertia.post(route('store.locumRequests.store'), data, options)
        }

    }, [locumRequest, dates, sendType, customPool, processing])

    // popup options
    const popupButtons = useMemo(() => {

        if (disabled){
            return [{
                handler: () => {
                    onClose();
                },
                text: `Close`,
                cssClass: `mbsc-popup-button-primary`
            }]
        }

        return ['cancel', {
            handler: () => {
                onSave();
            },
            text: `${locumRequest ? 'Update' : 'Create'}`,
            cssClass: `mbsc-popup-button-primary ${processing ? 'disabled' : ''}`
        }]

    }, [locumRequest, dates, sendType, customPool, processing, disabled]);

    // popup header
    const headerText = useMemo(() => {

        return `${locumRequest ? (disabled ? 'View' : 'Update') : 'Create'} Locum Request`

    }, [locumRequest]);

    //Set the date which matches to index of existing dates
    const handleDate = useCallback((date, index) => {
        const newDates = [...dates]

        newDates[index] = date

        return setDates(newDates)

    }, [dates])

    //Set dates from calendar modal on confirm
    const handleNewDates = useCallback((newDates) => {
        const newDatesFormatted = newDates.map(date => {

            const momentDate = moment(date, 'DD/MM/YYYY')

            const dayRate = store.all_day_rates.find(dayRate => dayRate.pivot.day == momentDate.format('dddd'))

            return {
                date: momentDate.format('DD/MM/YYYY'),
                start: dayRate.pivot.start,
                finish: dayRate.pivot.finish,
                rate: dayRate.rate,
            }

        })

        setDates([...dates].concat(newDatesFormatted))

    }, [dates])

    //Invalid Calendar Dates
    const invalidDates = useMemo(() => {
        return store.all_day_rates.filter(dayRate => dayRate.deleted_at).map(dayRate => moment(dayRate.pivot.day, 'dddd').format('dd')).join()
    }, [store])

    return (<>
        <Popup
            themeVariant='light'
            display="center"
            fullScreen={false}
            contentPadding={true}
            disabled={processing}
            headerText={headerText}
            buttons={popupButtons}
            scrollLock={false}
            isOpen={isOpen}
            onClose={onClose}
            cssClass="locum-request-modal"
        >
            {/*Dates*/}
            <div className='dates w-full float-left d-block' key={locumRequest?.id}>
				<div className="float-left w-full block modal-splitters">
                {dates.map((requestDate, index) => {
                     return <RequestDate key={`${requestDate?.date}_${index}`} requestDate={requestDate}
                                     canDelete={!locumRequest}
                                     disabled={disabled}
                                     removeDate={e => setDates(dates.filter((ele, i) => i != index))}
                                     setData={date => handleDate(date, index)}
                        />
                    }
                )}
				</div>
            </div>

            {/*Add Dates Via Calendar*/}
            {
                !locumRequest && <>
                <div className="flex w-full mt-4 justify-end items-center content-center">
					<label className="label mr-2 button-label title">Add Dates</label>
                    <Button className='float-right btn-sm btn-icon ' onClick={e => setCalendarOpen(true)} type='button' style="positive">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" className="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15"/>
                        </svg>
                    </Button>
                </div>
                <CalendarDatePicker {...{invalidDates, disabled}} addDates={handleNewDates} isOpen={isCalendarOpen}
                                    setOpen={setCalendarOpen}/>
            </>}

            {/*Send Options*/}
            <SendType {...{locumRequest, disabled}} type={sendType} setType={setSendType} setCustomPool={setCustomPool} />


        </Popup>
    </>)
}

export {LocumRequest as default, rateToObj}
