import moment from "moment";
import {useCallback} from "react";

const NegotiationPill = ({locumRequest, negotiation, setViewOpen, setAuditOpen, setNegotiation}) => {

    const openViewModal = useCallback(() => {
        setNegotiation(negotiation)
        setViewOpen(true)
    }, locumRequest)

    const openAuditModal = useCallback(() => {
        setNegotiation(negotiation)
        setAuditOpen(true)
    }, locumRequest)

    return (
	<div className="w-full block float-left full-width-split">
		<div className="w-full flex justify-center items-start content-start flex-wrap relative full-width-split-inner">
			<div className="grid grid-cols-6 gap-3 relative locum-pill w-full">
			
				<div className="col-span-3 md:col-span-2">
					<div className="w-full d-block locum-meta">
							<div className="w-full font-bold title">{negotiation.locum?.data?.first_name} {negotiation.locum?.data?.last_name}</div>
            				<div className="w-full">{moment(negotiation.start, 'HH:mm:ss').format('H:mm')} - {moment(negotiation.finish, 'HH:mm:ss').format('H:mm')}</div>
					</div>
				</div>
				
				<div className="col-span-1 md:col-span-1">
					<div className="w-full d-block locum-meta">
						<div className="w-full font-bold">Rate</div>
                    	<div className="w-full">£{negotiation.rate}</div>
					</div>
				</div>
				
				<div className="col-span-2 md:col-span-1">
					<div className="w-full d-block locum-meta">
						<div className="w-full font-bold">Status</div>
                    	<div className="w-full">{negotiation.status}</div>
					</div>
				</div>
				
				<div className="col-span-6 md:col-span-2">
					<div className="float-right flex negotiations-admin-margin">
						<div onClick={openViewModal} className="btn btn-primary btn-sm btn-icon">		
						
						
						
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M493.255 56.236l-37.49-37.49c-24.993-24.993-65.515-24.994-90.51 0L12.838 371.162.151 485.346c-1.698 15.286 11.22 28.203 26.504 26.504l114.184-12.687 352.417-352.417c24.992-24.994 24.992-65.517-.001-90.51zM164.686 347.313c6.249 6.249 16.379 6.248 22.627 0L368 166.627l30.059 30.059L174 420.745V386h-48v-48H91.255l224.059-224.059L345.373 144 164.686 324.687c-6.249 6.248-6.249 16.378 0 22.626zm-38.539 121.285l-58.995 6.555-30.305-30.305 6.555-58.995L63.255 366H98v48h48v34.745l-19.853 19.853zm344.48-344.48l-49.941 49.941-82.745-82.745 49.941-49.941c12.505-12.505 32.748-12.507 45.255 0l37.49 37.49c12.506 12.506 12.507 32.747 0 45.255z"/></svg>
					
			
								
					
					
					</div>
					
					<div onClick={openAuditModal} className="btn btn-primary btn-sm btn-icon ml-3"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M32.39 224C14.73 224 0 238.33 0 256s14.73 32 32.39 32a32 32 0 0 0 0-64zm0-160C14.73 64 0 78.33 0 96s14.73 32 32.39 32a32 32 0 0 0 0-64zm0 320C14.73 384 0 398.33 0 416s14.73 32 32.39 32a32 32 0 0 0 0-64zM504 80H136a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h368a8 8 0 0 0 8-8V88a8 8 0 0 0-8-8zm0 160H136a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h368a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8zm0 160H136a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h368a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8z"/></svg>
					</div>
					</div>
					
				</div>
				
				
			
			</div>
		</div>
	</div>
	
	)
}

export {NegotiationPill as default}
