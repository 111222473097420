import {useEffect, useMemo, useState} from "react";
import Checkbox from "../../../../ComponentPackage/Inputs/Checkbox";
import Field from "../../../../ComponentPackage/Inputs/Field";
import {useForm} from "@inertiajs/inertia-react";
import Button from "../../../../ComponentPackage/Button";


const formatLocumWorkPreferences = (locum) => ({
    companies: locum?.companies?.map(company => company.id) ?? []
})

const WorkPreferences = ({companies, locum}) => {

    const {setData, patch, processing, errors} = useForm(formatLocumWorkPreferences(locum))

    function submit(e) {
        e.preventDefault();

        patch(route('admin.locums.update', [locum.id, 'work-preferences']), {preserveScroll: true})
    }

    const [companiesValue, setCompaniesValue] = useState(locum?.companies?.map(company => company.id) ?? [])

    useEffect(() => {
        setData({
            'companies': companiesValue
        })
    }, [companiesValue])

    const companyOptions = useMemo(() => companies.map(company => ({
        label: company.name,
        className: "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500",
        name: 'work_preference',
        value: company.id,
        handleChange: (checked, value, event) => {
            if (checked) {
                setCompaniesValue(companiesValue.concat([value]));
            } else {
                setCompaniesValue(companiesValue.filter(item => item != value))
            }
        },
        checked: companiesValue.find(companyId => companyId == company.id)
    })), [companiesValue])


    return (
        <>
            <div className="grid grid-cols-6 gap-5">
                    {companyOptions.map((company, index) => 
					<div className="col-span-6 md:col-span-2">
					<div className="relative flex items-center" key={index}>
                        <div className="flex items-center">
                            <Checkbox {...company} />
                        </div>
                        <div className="ml-3 text-sm">
                            <label className="font-medium text-gray-700">
                                {company.label}
                            </label>
                        </div>
                    </div>
					</div>
					)}
					
					<div className="col-span-6 md:col-span-6">
                    <div className="flex items-center justify-end  mt-1">
                        <Button style="positive" processing={processing} onClick={e => submit(e)}>
                            Update Work Preferences
                        </Button>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export {WorkPreferences as default}
