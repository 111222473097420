import {Popup} from "@mobiscroll/react";
import {useCallback, useMemo} from "react";

const Negotiations = ({negotiations, setOpen, isOpen}) => {

    //rest modal on close
    const onClose = useCallback(() => {
        setOpen(false);
    }, []);


    // popup options
    const popupButtons = useMemo(() => {

        return [{
            handler: () => {
                onClose();
            },
            text: `Close`,
            cssClass: `mbsc-popup-button-primary`
        }]

    }, [negotiations]);

    return (<>
        <Popup
            themeVariant='light'
            display="center"
            fullScreen={false}
            contentPadding={true}
            headerText='View Negotiations'
            buttons={popupButtons}
            scrollLock={false}
            isOpen={isOpen}
            onClose={onClose}
            cssClass="locum-request-negotiations-modal"
        >
            Coming soon
        </Popup>
    </>)
}

export {Negotiations as default}
