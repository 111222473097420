import {Popup} from "@mobiscroll/react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import axios from "axios";
import route from "ziggy-js/src/js";
import Input from "../../../../ComponentPackage/Inputs/Input";
import Button from "../../../../ComponentPackage/Button";
import Comment from "./Comment";
import { router } from '@inertiajs/react'
import {Inertia} from "@inertiajs/inertia";

const Comments = ({locumRequest, isOpen, setOpen}) => {

    const [comments, setComments] = useState([])
    const [errors, setErrors] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(false)

    const [newComment, setNewComment] = useState('')

    const messageArea = useRef()

    const icons = useMemo(() => {
        const iconClassNames = 'w-6 h-6 float-left mr-4'
        return {
            'store' : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                           stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"/>
            </svg>,
            'locum': <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"/>
            </svg>,
        }
    }, [])

    useEffect(() => {

        if (!locumRequest) {
            setComments([])
        } else {
            axios.get(route('api.locum-request.comments.index',
                {
                    locumRequest: locumRequest.id, include: ['auth'], markAsRead: 'store'
                }),
                {
                before: (xhr) => {
                    setLoading(true)
                }
            }).then(response => {
                if ('data' in response && 'data' in response.data) {

                    const flipped = (response?.data?.data ?? []).reverse()
                    setComments(flipped)
                    setLoading(false)
                    messageArea.current.scrollIntoView()
                }

            }).catch(() => {
                console.log('hitting catch')
            })
        }


    }, [locumRequest])

    //rest modal on close
    const onClose = useCallback(() => {
        setOpen(false);

        if (locumRequest?.store_unread_total) {
            router.reload({
                only: ['locumRequests'],
                preserveScroll: true,
            })
        }
    }, [locumRequest]);


    //rest modal on close
    const sendMessage = useCallback(() => {

        if (!locumRequest)
            return true

        setProcessing(true)

        axios.post(route('api.locum-request.comments.store', [locumRequest?.id]),
            {'auth_id': locumRequest.store_id, 'auth_type': 'store', 'comment': newComment}
        ).then(response => {
            const newComments = [...comments]

            newComments.push(response?.data?.data)

            setComments(newComments)

            setNewComment('')

            setProcessing(false)


            })
            .catch((error) => {
                // setErrors(errors)
                setProcessing(false)
                setErrors(error?.response?.data?.errors)
            })

    }, [newComment, comments, locumRequest]);


    // popup options
    const popupButtons = useMemo(() => {

        return [{
            handler: () => {
                onClose();
            }, text: `Close`, cssClass: `mbsc-popup-button-primary`
        }]

    }, []);

    return (<>
        <Popup
            themeVariant='light'
            display="center"
            fullScreen={false}
            contentPadding={true}
            headerText='View Comments'
            buttons={popupButtons}
            scrollLock={false}
            isOpen={isOpen}
            onClose={onClose}
            cssClass="locum-request-negotiations-modal"
        >
            <div className="flex-1 justify-between flex flex-col">
                <div id="messages"
                     className="flex flex-col space-y-4 p-3 scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                    {comments.map((comment, index) => <Comment
                        key={index}
                        comment={comment.comment}
                        icon={comment.auth_type == 'Store' ? icons.store : icons.locum}
                        position={comment.auth_type == 'Store' ? 'right' : 'left'}
                    /> )}
                </div>
                <div className="border-t-2 border-gray-200 pt-4 mb-2 sm:mb-0">
                    <div className="relative" ref={messageArea}>
                        <Input type='textarea' className="resize-none" value={newComment}
                               onChange={event => setNewComment(event.target.value)}
                               error={errors?.comment}
                        />
                        <Button processing={processing} type='button' className='my-4' onClick={sendMessage}>Send</Button>
                    </div>
                </div>
            </div>
        </Popup>
    </>)
}

export {Comments as default}
