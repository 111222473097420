import {useCallback, useEffect, useState} from "react";
import InputMask from "../../../../ComponentPackage/Inputs/InputMask";
import Field from "../../../../ComponentPackage/Inputs/Field";
import {convertArrayToObject, objMap} from "../../../../utilities";

const formatLocumDocuments = (documentTypes, locum) => {
    return objMap(convertArrayToObject(documentTypes.map(documentType => {
        let documentFound = locum.documents?.find(document => document.document_type_id == documentType.id)

        return {...documentType, ...{number: documentFound?.number ?? null}}
    }), 'id'), document => document.number)
}

const Documents = ({documentTypes, locum, setData, errors}) => {

    const [documents, setDocuments] = useState(formatLocumDocuments(documentTypes, locum))

    const handleChange = useCallback((id, value) => {
        let newDocuments = {...documents}

        newDocuments[id] = value

        setDocuments(newDocuments)

    }, [documents])

    useEffect(() => {
        setData(objMap(documents, document => ({number: document})))
    }, [documents])

    return (
        <>
            {documentTypes.map(document => (
                <div className="col-span-6 md:col-span-2 input-mask">
                    <Field label={`${document.name} ${document.required ? '*' : ''}`} name={document.id}
                           error={errors[document.id] ?? ''}
                    >
                        <InputMask className="transition form-input"
                            value={documents[document.id] ?? ''}
                                   onChange={(event) => {handleChange(document.id, event.target.value)}}
                                   type={document.name.split(' ')[0].toLowerCase()}/>
                    </Field>
                </div>
            ))}
        </>
    )
}

export {Documents as default, formatLocumDocuments}
