import Field from "../../../../ComponentPackage/Inputs/Field";
import {useEffect, useState} from "react";

const Contact = ({setData, errors}) => {

    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [passwordConfirmed, setPasswordConfirmed] = useState()

    useEffect(() => {

        setData({...{name, email, password}, ...{password_confirmation: passwordConfirmed}})
    }, [name, email, password, passwordConfirmed])


    return (<>
        <div className="grid grid-cols-1 gap-5 float-left clear-both w-full">
            <div className="col-span-1">
                <Field label="Contact Name *" name="name" value={name} onChange={(name, value) => setName(value)}
                       error={errors['user.name']} />
            </div>
            <div className="col-span-1">
                <Field label="Contact Email *" name="email" value={email} onChange={(name, value) => setEmail(value)}
                       error={errors['user.email']}/>
            </div>
            <div className="col-span-1">
                <Field label="Password *" type="password" name="password" value={password} onChange={(name, value) => setPassword(value)}
                       error={errors['user.password']}/>
            </div>
            <div className="col-span-1">
                <Field label="Confirm Password *" type="password" name="password" value={passwordConfirmed} onChange={(name, value) => setPasswordConfirmed(value)}
                       error={errors['user.password']}/>
            </div>
        </div>
    </>)
}

export {Contact as default}
