import LocumRequest from "../Components/LocumRequest";
import Negotiations from "../Components/Negotiations";
import CompletedPill from "../Components/CompletedPill";
import {useMemo, useState} from "react";
import useQueryString from "../../../../Hooks/useQueryString";
import FilterOption from "../Components/FilterOption";
import FilterDisplayPill from "../Components/FilterDisplayPill";
import DateFilter from "../Components/DateFilter";
import LocumFilter from "../Components/LocumFilter";
import TablePaginationElement from "../../../../ComponentPackage/Tables/TablePaginationElement";

const Completed = ({locumRequests, store, locum}) => {

    const [isDateFilterOpen, setDateFilterOpen] = useState(false)
    const [dateFilter, setDateFilter] = useQueryString("date", '')

    const [isLocumFilterOpen, setLocumFilterOpen] = useState(false)
    const [locumFilter, setLocumFilter] = useQueryString("locum_id", '')

    const [isViewModalOpen, setViewModalOpen] = useState(false)
    const [locumRequest, setLocumRequest] = useState({})


    const icons = useMemo(() => {
        const iconClassNames = ''
        return {
            'calendar' : <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                              viewBox="0 0 24 24" stroke-width="1.5"
                              stroke="currentColor"
                              className="w-6 h-6 inline-block">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"/>
            </svg>,
            'person': <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                           stroke="currentColor" className="w-6 h-6 inline-block">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"/>
            </svg>,


        }
    }, [])

    return (<>
        <div>
            <FilterOption setFilterOpen={setDateFilterOpen} label={<>Filter Date {icons.calendar}</>} />
            <FilterOption setFilterOpen={setLocumFilterOpen} label={<>Filter Locum {icons.person}</>} />
        </div>
        <div>
            {dateFilter && <FilterDisplayPill message={`Date: ${dateFilter}`} onRemove={e => setDateFilter(null)}/>}
            {locum && <FilterDisplayPill message={`Locum: ${locum?.first_name} ${locum?.last_name}`} onRemove={e => setLocumFilter(null)}/>}
        </div>

        {locumRequests?.data?.map(locumRequest => <CompletedPill {...{locumRequest, setLocumRequest, setViewModalOpen}}  />)}
        {locumRequests?.meta?.pagination && <TablePaginationElement pagination={locumRequests?.meta?.pagination} /> }
        <LocumRequest disabled={true} setLocumRequest={setLocumRequest} locumRequest={locumRequest} isOpen={isViewModalOpen} setOpen={setViewModalOpen} store={store} />
        <DateFilter setDate={setDateFilter} setOpen={setDateFilterOpen} isOpen={isDateFilterOpen} date={dateFilter}/>
        <LocumFilter setLocum={setLocumFilter} setOpen={setLocumFilterOpen} isOpen={isLocumFilterOpen} locum={locum}/>

    </>)
}

export {Completed as default}
