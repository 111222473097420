import {useCallback, useEffect, useMemo, useState} from "react";
import {objMap} from "../../../../utilities";
import {Inertia} from "@inertiajs/inertia";
import {Popup} from "@mobiscroll/react";
import Modal from "../../../../ComponentPackage/Modals/Modal";
import Button from "../../../../ComponentPackage/Button";
import {ExclamationCircleIcon} from "@heroicons/react/24/outline";
import {Dialog} from "@headlessui/react";

const RemoveLocum = ({isOpen, setOpen, locum}) => {

    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    const onDelete = useCallback(() => {

        const data = {locum_id: locum?.id}

        Inertia.delete(route('store.trustpool.destroy', locum?.id), {onSuccess: e => onClose()})

    }, [locum])

    // popup options
    const headerText = useMemo(() => {

        return `Remove ${locum?.first_name} ${locum?.last_name} from Trustpool`

    }, [locum]);

    const contentText = useMemo(() => {

        return `Are you sure you would like to remove ${locum?.first_name} ${locum?.last_name} from Trustpool`

    }, [locum]);

    const ConfirmButton = () => <Button style="positive" onClick={() => onDelete()}>
        Confirm
    </Button>

    const Content = () => <div className="sm:flex sm:items-start">
        
        <div className="text-left">
            <Dialog.Title as="h3" className="modal-title">
                {headerText}
            </Dialog.Title>
            <p className="float-left w-full d-block">
                {contentText}
            </p>
        </div>
    </div>

    return (<>
        <Modal setOpen={setOpen} open={isOpen} confirmButton={<ConfirmButton/>} children={<Content/>}/>
    </>)
}

export {RemoveLocum as default}
