import React from 'react';
import {InertiaLink} from "@inertiajs/inertia-react";
import {Inertia} from "@inertiajs/inertia";
import useQueryString from "../../../Hooks/useQueryString";
import {Table} from "../../../ComponentPackage/Tables";
import Authenticated from "../../Admin/Layouts/Authenticated";
import PageTitle from "../../../ComponentPackage/PageTitle";
import {Section} from "../../../ComponentPackage/Sections";
import DeleteConfirmation from "../../../ComponentPackage/Modals/DeleteConfirmation";
import ActivationButton from "./Components/ActivationButton";
import Field from "../../../ComponentPackage/Inputs/Field";
import Datepicker from "../../../ComponentPackage/Inputs/Datepicker";
import AsyncSelect from "../../../ComponentPackage/Selects/AsyncSelect";

const config = {
    columns: [
        {
            title: "Company",
            render: (store) => (
                <>
                    <span className="text-sm text-gray-500">{store?.company}</span>
                </>
            )
        },
        {
            title: "Name",
            render: store => (
                <span className="text-sm text-gray-500">{store?.name}</span>
            )
        },
        {
            title: "Number",
            render: store => (
                <span className="text-sm text-gray-500">{store?.number}</span>
            )
        },
        {
            title: "Address",
            render: store => (
                <span className="text-sm text-gray-500">{store?.address}</span>
            )
        },
        {
            title: "Subscribed?",
            render: store => (
                <span className="text-sm text-gray-500">{store?.is_subscribed ? 'Yes' : 'No'}</span>
            )
        },
        {
            render: (store) => (
                <InertiaLink
                    href={route('admin.stores.show', [store.id])}
                    className="text-sm  btn-link"
                >View</InertiaLink>
            )
        },
        {
            render: store => (
                <ActivationButton store={store}/>
            )
        },
        {
            render: (store) => {
                return <DeleteConfirmation
                    isOpen={open}
                    message= {"Are you sure you want to delete?"}
                    title="Delete Store"
                    onConfirm={() => Inertia.delete(route('admin.stores.destroy', [store.id]), { preserveScroll: true })}
                />

            }
        }
    ]
};

const Index = ({ stores, auth }) => {
    const [searchValue, setSearchValue] = useQueryString("search", 'stores')
    const [addressValue, setAddressValue] = useQueryString("address", '')

    return (
        <Section header={{
            title: "All Stores",
            onSearchChange: value => setSearchValue(value),
            searchValue: searchValue,
            searchPlaceholder: "Search stores"
        }}>
            <div className="float-left w-full block mt-4 h-auto">
                    <Field label="Address" name="address" value={addressValue} onChange={(name, value) => setAddressValue(value)}/>
                <div className="float-left w-full block mt-5 h-auto">
                    <Table data={stores.data} meta={stores.meta} config={config} />
                </div>
            </div>
        </Section>
    );
};

Index.layout = page => (
    <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle
            title="Manage Stores"
        />}
    />
);


export default Index;
