import moment from "moment";
import {InertiaLink} from "@inertiajs/inertia-react";


const Overview = ({locumRequest, dayRate}) => {


    return (
        <>
			<div className="grid grid-cols-6 gap-5 locums-grid">
				<div className="col-span-6 md:col-span-2">
					<label className="label">Store</label>
					<p><InertiaLink
                                href={route('admin.stores.show', [locumRequest?.store?.id])}
                                className="text-sm  btn-link"
                            >{locumRequest?.store?.company?.name} {locumRequest?.store?.name && `(${locumRequest?.store?.name})`}</InertiaLink></p>
				</div>
				
				
				<div className="col-span-6 md:col-span-2">
					<label className="label">Locum</label>
					<p> {locumRequest?.locum &&
                                <InertiaLink
                                    href={route('admin.locums.show', [locumRequest?.locum?.id])}
                                    className="text-sm  btn-link"
                                >{locumRequest?.locum?.first_name} {locumRequest?.locum?.last_name}</InertiaLink>
                            }</p>
				</div>
				
				<div className="col-span-6 md:col-span-2">
					<label className="label">Date</label>
					<p> {moment(locumRequest?.from).format('DD/MM/YYYY')}</p>
				</div>
				
				<div className="col-span-6 md:col-span-2">
					<label className="label">Time</label>
					<p>{moment(locumRequest?.from).format('HH:mm')} - {moment(locumRequest?.to).format('HH:mm')}</p>
				</div>
				
				<div className="col-span-6 md:col-span-2">
					<label className="label">Equipment</label>
					<p>{locumRequest?.equipments?.map(equpiment => equpiment.name)?.join(', ')}</p>
				</div>
				
				<div className="col-span-6 md:col-span-2">
					<label className="label">OCT</label>
					<p>{locumRequest?.oct ? 'Yes' : 'No'}</p>
				</div>
				
				<div className="col-span-6 md:col-span-2">
					<label className="label">Contact Lenses</label>
					<p>{locumRequest?.contact_lenses ? 'Yes' : 'No'}</p>
				</div>
				
				<div className="col-span-6 md:col-span-2">
					<label className="label">Test Minutes</label>
					<p>{locumRequest?.test_time} Minutes</p>
				</div>
				
				<div className="col-span-6 md:col-span-2">
					<label className="label">Rate of Pay</label>
					<p>£{dayRate?.rate}</p>
				</div>
				
				<div className="col-span-6 md:col-span-2">
					<label className="label">Trustpool Rate</label>
					<p>£{dayRate?.trustpool_rate}</p>
				</div>
				
				<div className="col-span-6 md:col-span-2">
					<label className="label">Cancelled At</label>
					<p>{moment(locumRequest?.cancelled_at).format('DD/MM/YYYY HH:mm')}</p>
				</div>
				
				<div className="col-span-6 md:col-span-2">
					<label className="label">Cancelled By</label>
					<p>{locumRequest?.cancelled_by}</p>
				</div>
				
				<div className="col-span-6 md:col-span-2">
					<label className="label">Cancellation Reason</label>
					<p>{locumRequest?.reason}</p>
				</div>
			</div>
			
           
        </>
    )
}

export {Overview as default}
