import moment from "moment/moment";

const AuditItem = ({audit}) => {

    console.log(audit)
    return (
        <div className="auditor float-left w-full">
            <div className="flex justify-between float-left w-full auditor-top">
                <h3>{audit.message}<br/> {<small>By {audit?.auth_type == 'User' ? 'Store' : 'Locum'}</small>}</h3>

                <small className="ml-auto pl-6"> {moment(audit.created_at).fromNow()}</small>
                <div className="clear-both"></div>
            </div>
            {audit.changes && <div className="auditor-bottom float-left w-full">
                <ul>
                    {Object.keys(audit.changes).map((key, index) => (
                        <li>
                            <span>{key && key[0].toUpperCase() + key.slice(1)}</span><br/>
                            {`${audit.changes[key]}`}
                       </li>
                    ))}
                </ul>
            </div>}
        </div>
    )
}

export default AuditItem
