import AuditItem from "../../ComponentPackage/Auditor/AuditItem";
import React from "react";

const AuditLog = ({audits}) => {

    return (
        <div className="auditor-outer w-full block h-auto float-left">
            {!!(audits?.length) && audits?.map((item) => (<AuditItem audit={item} />))}
            {!!(!audits?.length) && <p>No audits logged</p>}
        </div>
    )
}

export default AuditLog
