import {Section} from "../../../../ComponentPackage/Sections";
import {Table} from "../../../../ComponentPackage/Tables";
import useQueryString from "../../../../Hooks/useQueryString";
import {locumsBookingTable} from "./UpcomingBookings";

const PreviousBookings = ({previousBookings, locum}) => {

    const [searchValue, setSearchValue] = useQueryString("search", '')

    return (
        <Section header={{
            title: "All Previous Bookings",
            onSearchChange: value => setSearchValue(value),
            searchValue: searchValue,
            searchPlaceholder: "Search Locum Requests",
        }}>
			<div className="float-left w-full block h-auto mt-5">
            <Table data={previousBookings.data} meta={previousBookings.meta} config={locumsBookingTable} />
			</div>
        </Section>
    );
};


export {PreviousBookings as default}
