import Button from "../../../../ComponentPackage/Button";
import moment from "moment";
import {useCallback} from "react";
import {Inertia} from "@inertiajs/inertia";
import route from "ziggy-js/src/js";


const BookedPill = ({locumRequest, setLocumRequest, setChatModalOpen, setViewModalOpen, setDeleteModalOpen}) => {

    const openDeleteModal = useCallback(() => {
        setLocumRequest(locumRequest)
        setDeleteModalOpen(true)
    }, locumRequest)

    const openViewModal = useCallback(() => {
        setLocumRequest(locumRequest)
        setViewModalOpen(true)
    }, locumRequest)

    const openChatModal = useCallback(() => {
        setLocumRequest(locumRequest)
        setChatModalOpen(true)
    }, locumRequest)

    const redirectNegotiations = useCallback(() => {
        Inertia.visit(route('store.locumRequests.negotiations.index', locumRequest.id))
    }, locumRequest)

    return (
        <div className="w-full flex justify-center items-start content-start flex-wrap relative full-width-split-inner">
            <div className="grid grid-cols-6 gap-3 relative locum-pill w-full">

                <div className="col-span-6">
                    <div className="flex justify-start title align-center items-center float-left assigned-user">
                        {!!locumRequest.is_locum_trustpool && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 245">
                            <path d="m56,237 74-228 74,228L10,96h240"/>
                        </svg>}

                        {locumRequest?.locum?.data?.first_name} {locumRequest?.locum?.data?.last_name}: {locumRequest?.locum?.data?.opl}
                    </div>
                </div>

                <div className="col-span-5 ">
                    <div className="w-full d-block locum-meta">


                        <div
                            className='w-full font-bold title'>{moment(locumRequest.date).format('ddd Do MMM YYYY')}</div>
                        <div
                            className='w-full'>{moment(locumRequest.start, 'HH:mm:ss').format('H:mm')} - {moment(locumRequest.finish, 'HH:mm:ss').format('H:mm')}</div>
                    </div>
                </div>
                <div className="col-span-1">
                    <div className="w-full d-block locum-meta">
                        <div className="w-full font-bold">Rate</div>
                        <div className="w-full">£{locumRequest.rate}</div>
                    </div>
                </div>
                <div className="col-span-6">
                    <div className="flex float-right mb-2 minus-margin">
                        <div className="btn btn-sm btn-primary btn-icon " onClick={redirectNegotiations}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm16 352c0 8.8-7.2 16-16 16H288l-12.8 9.6L208 428v-60H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v288zm-96-216H144c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h224c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm-96 96H144c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16z"/>
                            </svg>
                        </div>

                        <div className="btn btn-sm btn-primary btn-icon ml-2" onClick={openChatModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                <path
                                    d="M512 160h-96V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v160c0 35.3 28.7 64 64 64h32v52c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4l76.9-43.5V384c0 35.3 28.7 64 64 64h96l108.9 61.6c2.2 1.6 4.7 2.4 7.1 2.4 6.2 0 12-4.9 12-12v-52h32c35.3 0 64-28.7 64-64V224c0-35.3-28.7-64-64-64zM64 256c-17.6 0-32-14.4-32-32V64c0-17.6 14.4-32 32-32h288c17.6 0 32 14.4 32 32v160c0 17.6-14.4 32-32 32H215.6l-7.3 4.2-80.3 45.4V256zm480 128c0 17.6-14.4 32-32 32h-64v49.6l-80.2-45.4-7.3-4.2H256c-17.6 0-32-14.4-32-32v-96h128c35.3 0 64-28.7 64-64v-32h96c17.6 0 32 14.4 32 32z"/>
                            </svg>
                        </div>

                        <div className="btn btn-sm btn-primary btn-icon ml-2" onClick={openViewModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                <path
                                    d="M288 288a64 64 0 0 0 0-128c-1 0-1.88.24-2.85.29a47.5 47.5 0 0 1-60.86 60.86c0 1-.29 1.88-.29 2.85a64 64 0 0 0 64 64zm284.52-46.6C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 96a128 128 0 1 1-128 128A128.14 128.14 0 0 1 288 96zm0 320c-107.36 0-205.46-61.31-256-160a294.78 294.78 0 0 1 129.78-129.33C140.91 153.69 128 187.17 128 224a160 160 0 0 0 320 0c0-36.83-12.91-70.31-33.78-97.33A294.78 294.78 0 0 1 544 256c-50.53 98.69-148.64 160-256 160z"/>
                            </svg>
                        </div>

                        <div className="btn btn-sm btn-danger btn-icon ml-2" onClick={openDeleteModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path
                                    d="M296 432h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zm-160 0h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zM440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h24a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zM384 464a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320zm-168-32h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export {BookedPill as default}
