import React, {useCallback, useEffect, useMemo, useState} from "react";
import AsyncSelect from "../Selects/AsyncSelect";
import Field from "../Inputs/Field";

const AddressAutocomplete = ({data, onChange, errors, address, setData, disabled = false}) => {
    const [queryError, setQueryError] = useState(false)

    const [postcode, setPostcode] = useState(address?.postcode)
    const [line_1, setLine1] = useState(address?.line_1)
    const [line_2, setLine2] = useState(address?.line_2)
    const [line_3, setLine3] = useState(address?.line_3)
    const [city, setCity] = useState(address?.city)
    const [county, setCounty] = useState(address?.county)
    const [lng, setLng] = useState(address?.lng)
    const [lat, setLat] = useState(address?.lat)

    const handleQueryError = (data) => {
        setQueryError(true)
    }
    const handleQuerySuccess = (data) => {
        setQueryError(false)
    }

    const selectFormatData = useCallback((data) => {
        return data.addresses.map(address => ({...address, ...{lat: data.lat, lng: data.lon}}))
    }, [])

    const onAddressSelected = useCallback((postcode, object) => {
        setPostcode(object.postcode)
        setLine1(object.line1)
        setLine2(object.line2)
        setLine3(object.line3)
        setCounty(object.county)
        setCity(object.city)
        setLng(object.lng)
        setLat(object.lat)

    }, []);

    useEffect(() => {
        setData({postcode, line_1, line_2, line_3, city, county, lng, lat})

    }, [postcode, line_1, line_2, line_3, city, county, lng, lat])

    return (
        <>

			<div className="grid grid-cols-6 gap-5 float-left clear-both w-full">

            <div className="col-span-6">
                <Field name="line_1" label="Address Search">
                    <AsyncSelect routeName="api.address.getAddress"
                                 name="address_search"
                                 disabled={disabled}
                                 queryName="input"
                                 onChange={(name, value, object) => onAddressSelected(value, object)}
                                 onQueryFail={handleQueryError}
                                 onQuerySuccess={handleQuerySuccess}
                                 placeholder="Enter postcode to search for address"
                                 valueKey="fullAddress"
                                 labelGenerate={(data => data.fullAddress)}
                                 formatData={selectFormatData}
                                 error={queryError && 'Postcode was not found'}
                    />
                </Field>
            </div>
            <div className="col-span-6 md:col-span-3">
                <Field name="line_1" label="Address Line 1 *"
                       value={line_1} disabled={disabled}
                       onChange={(name, value) => setLine1(value)} error={errors?.line_1}/>
            </div>

            <div className="col-span-6 md:col-span-3">
                <Field name="line_2" label="Address Line 2"
                       value={line_2} disabled={disabled}
                       onChange={(name, value) => setLine2(value)} error={errors?.line_2}/>
            </div>

            <div className="col-span-6 md:col-span-3">
                <Field name="line_3" label="Address Line 3"
                       value={line_3} disabled={disabled}
                       onChange={(name, value) => setLine3(value)} error={errors?.line_3}/>
            </div>

            <div className="col-span-6 md:col-span-3">
                <Field name="city" label="City *" disabled={disabled}
                       value={city} onChange={(name, value) => setCity(value)}
                       error={errors?.city}/>
            </div>

            <div className="col-span-6 md:col-span-3">
                <Field name="county" label="County *" value={county} error={errors?.county}
                       onChange={(name, value) => setCounty(value)} disabled={disabled}/>
            </div>

            <div className="col-span-6 md:col-span-3">
                <Field name="postcode" label="Postcode *"
                       value={postcode} disabled={disabled}
                       onChange={(name, value) => setPostcode(value)} error={errors?.postcode}
                />
            </div>

			</div>
        </>
    )
}

export default AddressAutocomplete
