import React from 'react';

const PageTitle = ({ title, actions }) => {

    return (
        <>
            <div className="flex flex-1 min-w-0 items-center content-center justify-start">
                <h2 className="">{ title }</h2>
            </div>
            <div className="fixed-button">
                { actions && actions.map((action, index) => action({ key: index })) }
            </div>
        </>
    );
}

export default PageTitle;
