import Button from "../../../../ComponentPackage/Button";
import LocumRequest from "./LocumRequest";
import {useState} from "react";

const CreateLocumRequest = ({store}) => {

    const [isCreateModalOpen, setCreateModalOpen] = useState(false)

    return <>
        <Button type="button" style="primary" onClick={() => setCreateModalOpen(true)}>Create</Button>
        <LocumRequest isOpen={isCreateModalOpen} setLocumRequest={() => {}} setOpen={setCreateModalOpen} store={store} />
    </>
}

export {CreateLocumRequest as default}
