import React from "react"

import Form from "./Form";
import Authenticated from "../Layouts/Authenticated";
import Button from "../../../ComponentPackage/Button";
import {Section} from "../../../ComponentPackage/Sections";
import {useForm} from "@inertiajs/inertia-react";
import PageTitle from "../../../ComponentPackage/PageTitle";

const Create = () => {

    const {data, setData, post, processing, errors} = useForm({
        name: '',
        email: '',
    })

    function submit(e) {
        e.preventDefault()

        post(route('admin.users.store'))
    }

    return (
        <Section>
            <div>
                <Form data={data} errors={errors} post={post} processing={processing} setData={setData}>
                    <div className="col-span-6 sm:col-span-6">
                        <div className="flex items-center justify-end  mt-1">
                            <Button style="positive" processing={processing} onClick={e => submit(e)}>
                                Create
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </Section>
    )
}

Create.layout = page => (
    <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle title={`Create User`}/>}
    />
)

export {Create as default}
