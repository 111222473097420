import React from 'react';
import { render } from 'react-dom';
import { createInertiaApp } from '@inertiajs/inertia-react';
import {Inertia} from "@inertiajs/inertia";
import {snackbar} from "@mobiscroll/react";
import moment from "moment";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

const axios = require('axios').default;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

moment.updateLocale('en', {
    week: {
        dow : 1, // Monday is the first day of the week.
    }
})


Inertia.on('success', (event) => {

    const props = event.detail.page.props;

    if(props.flash && props.flash.success) {
        snackbar({
            message: props.flash.success,
            color: 'success'
        })
    }

    if(props.flash && props.flash.error) {
        snackbar({
            message: props.flash.error,
            color: 'danger'
        })
    }
})

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => require(`./inertia/Pages/${name}`),
    setup({ el, App, props }) {
        return render(<App {...props} />, el);
    },
});


