import {Section} from "../../../ComponentPackage/Sections";
import {InertiaLink} from "@inertiajs/inertia-react";
import useQueryString from "../../../Hooks/useQueryString";
import {Table} from "../../../ComponentPackage/Tables";
import PageTitle from "../../../ComponentPackage/PageTitle";
import moment from "moment";
import Authenticated from "../Layouts/Authenticated";


const config = {
    columns: [
        {
            title: "Locum Request",
            render: (negotiation) => (
                <>
                    <InertiaLink
                        href={route('store.locumRequests.negotiations.index', negotiation.locum_request_id)}
                        className="btn-link"
                    >
						{negotiation.locum?.data?.first_name} {negotiation.locum?.data?.last_name}<br />
                        {moment(negotiation?.locumRequest?.data?.date).format('DD/MM/YYYY')}<br />
						{moment(negotiation.start, 'HH:mm:ss').format('H:mm')} - {moment(negotiation.finish, 'HH:mm:ss').format('H:mm')}

                    </InertiaLink>
                </>
            )
        },
        {
            title: "Status",
            render: negotiation => (
                <span className="text-sm text-gray-500">{negotiation.status}</span>
            )
        },
        {
            title: "Rate",
            render: negotiation => (
                <span className="text-sm text-gray-500">£{negotiation.rate}</span>
            )
        },
        {
            title: "Start",
            render: negotiation => (
                <span className="text-sm text-gray-500">{moment(negotiation.start, 'HH:mm:ss').format('H:mm')}</span>
            )
        },
        {
            title: "Finish",
            render: negotiation => (
                <span className="text-sm text-gray-500">{moment(negotiation.finish, 'HH:mm:ss').format('H:mm')}</span>
            )
        },
        {
            render: (negotiation) => (
                <InertiaLink
                    href={route('store.locumRequests.negotiations.index', [negotiation.locum_request_id])}
                    className=" btn-link"
                >View</InertiaLink>
            )
        },
    ]
};

const Index = ({ negotiations, auth }) => {
    const [searchValue, setSearchValue] = useQueryString("search", 'negotiations')

    return (
        <Section header={{
            title: "All Negotiations",
        }}>

                {!!negotiations?.data?.length && <Table data={negotiations.data} meta={negotiations.meta} config={config}/>}
                {!negotiations?.data?.length && <p className="none w-full float-left text-center">Sorry, you currently have no active negotiations</p>}
        </Section>
    );
};

Index.layout = page => (
    <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle
            title="Manage Negotiations"
        />}
    />
);

export {Index as default}
