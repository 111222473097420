const Comment = ({position, comment, icon}) => {

    return <div className="chat-message">
        <div className={`flex items-end ${position == 'right' ? 'justify-end' : ''}`}>
            <div className={`flex flex-col space-y-2 text-xs max-w-xs mx-2 ${position == 'right' ? 'order-1 items-end' : 'order-2 items-start'}`}>
                <div>
                    <span className={`px-4 py-2 rounded-lg inline-block ${position == 'right' ? 'rounded-br-none bg-blue-600 text-white ' : 'rounded-bl-none bg-gray-300 text-gray-600'}`}>
                        {comment}
                    </span>
                </div>
            </div>
            <div className={`${position == 'right' ? 'order-2' : ''}`}>
                {icon}
            </div>
        </div>
    </div>;
}

export {Comment as default}
