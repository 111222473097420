import Tabs from "../../../ComponentPackage/Containers/Tabs";
import {Section} from "../../../ComponentPackage/Sections";
import Pending from "./Tabs/Pending";
import Completed from "./Tabs/Completed";
import Booked from "./Tabs/Booked";
import PageTitle from "../../../ComponentPackage/PageTitle";
import Authenticated from "../Layouts/Authenticated";
import TabIcon from "./Icons/TabIcon";
import {useMemo, useState} from "react";
import CreateLocumRequest from "./Components/CreateLocumRequest";

const Index = ({tab, store, totalPending, totalBooked, totalCompleted, locumRequests, locum}) => {

    const icons = useMemo(() => {
        return {

        }
    }, [])

    const tabs = [
        { label: <TabIcon label='Pending' icon={icons.pending} className='pending' number={`${totalPending}`} />, href: `/locum-requests`,
            name: 'pending', Render: <Pending store={store} locumRequests={locumRequests}/>, classNames: 'iconTab'},
        { label: <TabIcon label='Booked' icon={icons.pending} className='booked' number={`${totalBooked}`} />, href: `/locum-requests/booked`,
            name: 'booked', Render: <Booked locumRequests={locumRequests} {...{store, locum}}/>, classNames: 'iconTab' },
        { label: <TabIcon label='Completed' icon={icons.pending} className='completed' number={`${totalCompleted}`} />, href: `/locum-requests/completed`,
            name: 'completed', Render: <Completed locumRequests={locumRequests} store={store}/>, classNames: 'iconTab' },
    ];


    return (<>
        <Section>
			<div className="w-full block float-left full-width-split shift-tabs">
            	<Tabs tabs={tabs} activeTabName={tab ?? 'pending'} />
			</div>
        </Section>
    </>)
}

Index.layout = page => <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle
            title="Locum Requests"
            actions={[
                props => <CreateLocumRequest store={page.props.store} />
            ]}
        />}
    />

export {Index as default}
