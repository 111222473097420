import Authenticated from "../Layouts/Authenticated";
import PageTitle from "../../../ComponentPackage/PageTitle";
import {Section} from "../../../ComponentPackage/Sections";
import Field from "../../../ComponentPackage/Inputs/Field";
import {useCallback, useState} from "react";
import {Inertia} from "@inertiajs/inertia";
import Button from "../../../ComponentPackage/Button";

const Contact = ({}) => {
    const [errors, setErrors] = useState()
    const [processing, setProcessing] = useState()

    const [message, setMessage] = useState()

    const onSave = useCallback(() => {
        Inertia.post(route('store.settings.contact-us.store'), {message}, {
            onError: errors => {
                setErrors(errors)
                setProcessing(false)
            },
            onSuccess: e => setProcessing(false),
            onBefore: e => setProcessing(true)
        })
    }, [message])

    return <>
        <Section className="clear-both float-left w-full block">
			<div className="grid grid-cols-1 gap-5">
				<div className="col-span-1">
					<Field label='Message *' error={errors?.message} type='textarea'
                   onChange={(name, value) => setMessage(value)} value={message}/>
				</div>
				
				<div className="col-span-1">
					<div className="float-right  mt-1">
						<Button style="positive" processing={processing} onClick={onSave}>
							Send Message
						</Button>
					</div>
				</div>
			</div>
			
            

            

        </Section>
    </>
}

Contact.layout = page => <Authenticated
    children={page}
    auth={page.props.auth}
    header={<PageTitle title='Contact Us'/>}
/>

export {Contact as default}
