import Authenticated from "../Layouts/Authenticated";
import PageTitle from "../../../ComponentPackage/PageTitle";
import Form from "./Form";
import Button from "../../../ComponentPackage/Button";
import {Section} from "../../../ComponentPackage/Sections";
import {useForm} from "@inertiajs/inertia-react";

const Show = ({locum, tab, companies, documentTypes, previousBookings, upcomingBookings, cancelledBookings}) => {

    return (
        <Section>
            <Form locum={locum} companies={companies} tab={tab} documentTypes={documentTypes}
                  previousBookings={previousBookings} upcomingBookings={upcomingBookings}
                  cancelledBookings={cancelledBookings}
            />
        </Section>
    )
}

Show.layout = page => (
    <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle title={`Locum Overview (${page.props.locum.name})`}/>}
    />

)

export {Show as default}
