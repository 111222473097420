import moment from "moment/moment";
import {Datepicker as DatepickerReact} from "@mobiscroll/react";
import React, {useEffect, useState} from "react";
import Error from "../../ComponentPackage/Inputs/Error";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";

const Datepicker = ({name, value, error, onChange, type, min, parent, customOptions = {}, disabled}) => {

    const handleChange = (element, config) => {
        if (value != element.valueText)
            onChange(config.props.name, element.valueText)
    };

    let options = {
        theme: 'material',
        themeVariant: 'light'
    }


    switch (type) {
        case 'range':
            options = Object.assign(options, {
                controls: ['calendar'],
                select: "range",
                dateFormat: "DD/MM/YYYY",
            });
            break;
        case 'timegrid':
            options = Object.assign(options, {
                controls: ['timegrid'],
                timeFormat: "HH:mm",
                minTime: '07:00',
                maxTime: '20:00',
                stepMinute: 15
            });
            break;
        case 'time':
            options = Object.assign(options, {
                controls: ['time'],
                timeFormat: 'HH:mm',
                stepMinute: 15
            });
            break;
        case 'date':
            options = Object.assign(options, {
                dateFormat: "DD/MM/YYYY",
                min: min ?? null,
                controls: ['date'],
            });
            break;
        case 'dates':
            options = Object.assign(options, {
                dateFormat: "DD/MM/YYYY",
                weekDays: "'MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'",
                min: min ?? null,
                controls: ['calendar'],
                selectMultiple: true
            });

            break;
        case 'datetime':
            options = Object.assign(options, {
                dateFormat: "DD/MM/YYYY",
                controls: ['date', 'time'],
                timeFormat: "HH:mm",
                min: min ?? null,
                minTime: '06:00',
                maxTime: '18:00',
            });
            break;
        case 'datetimeRange':
            options = Object.assign(options, {
                dateFormat: "DD/MM/YYYY",
                controls: ['calendar', 'time'],
                select: "range",
                timeFormat: "HH:mm",
                min: min ?? null,
                minTime: '06:00',
                maxTime: '18:00',
            });
        case 'breakTime':
            options = Object.assign(options, {
                controls: ['timegrid'],
                timeFormat: "HH:mm",
                minTime: '00:05',
                maxTime: '02:00',
                stepMinute: 5
            });
            break;
    }

    options = {...options, ...customOptions}


    return (
        <>
            <div className="float-left w-full block h-auto datepicker relative">
                <DatepickerReact
                    {...options}
                    moment={moment}
                    returnFormat="moment"
                    value={value}
                    onChange={handleChange}
                    name={name}
                    touchUi={false}
                    disabled={disabled}
                />

                {
                    error &&
                    <Error message={error}/>
                }
            </div>
        </>
    )
}

export default Datepicker
