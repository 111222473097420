import Field from "../../../../ComponentPackage/Inputs/Field";
import YesOrNo from "../../../../ComponentPackage/Inputs/YesOrNo";
import Checkbox from "../../../../ComponentPackage/Inputs/Checkbox";
import DayRates from "../../../Admin/Stores/Components/DayRates";
import {Inertia} from "@inertiajs/inertia";
import {useCallback, useState} from "react";
import Button from "../../../../ComponentPackage/Button";
import Equipment from "../Components/Equipment";
import Title from "../../../../ComponentPackage/Title";
import {formatEquipment} from "../Components/Equipment";

const Clinic = ({store, dayRates}) => {

    const [errors, setErrors] = useState()
    const [processing, setProcessing] = useState()

    const [hasNotifications, setHasNotifications] = useState(store?.receive_email_booking_notification ?? 0)
    const [dayRatesValue, setDayRatesValue] = useState(dayRates ?? null)
    const [equipment, setEquipment] = useState((({ test_time, oct, phoropter, trial_frame, contact_lenses }) => ({ test_time, oct, phoropter, trial_frame, contact_lenses }))(store))

    console.log(dayRates)

    const onUpdate = useCallback(() => {

        const data = {
            ...{day_rates: dayRatesValue, receive_email_booking_notification: hasNotifications},
            ...equipment
        }


        Inertia.patch(route('store.profile.clinic.update'), data, {
            onError: errors => {
                setErrors(errors)
                setProcessing(false)
            },
            onSuccess: e => setProcessing(false),
            onBefore: e => setProcessing(true)
        })
    }, [equipment, dayRatesValue, hasNotifications])



    return <>
        	<div className="grid grid-cols-1 gap-5">
            <div className="col-span-1">
                <h3 className="title-row">Equipment</h3>
			</div>
			<div className="col-span-1 ">
				<div className="mb-2 w-full float-left block">
                    <Equipment equipment={store} setData={setEquipment} errors={errors}/>
				</div>
            </div>

            {dayRates && <>
                <div className="col-span-1">
                    <h3  className="title-row">Open Days, hours & rates:</h3>
				</div>
				<div className="col-span-1">
                    <DayRates errors={errors} dayRates={dayRates} setData={setDayRatesValue}/>
                </div>
            </>}

            <div className="col-span-1 checkbox-alone">
                <Field className="" label="Receive Email Booking Notifications" error={errors?.receive_email_booking_notifications}>
                    <Checkbox name="has_notifications" value={1} checked={!!hasNotifications}
                              handleChange={(checked) => setHasNotifications(checked ? 1 : 0)}/>
                </Field>
            </div>

             <div className="col-span-1">
                <div className="flex items-center justify-end  mt-2">
                    <Button style="positive" processing={processing} onClick={onUpdate}>
                        Update Store Clinic
                    </Button>
                </div>
            </div>



        </div>
    </>
}

export {Clinic as default}
