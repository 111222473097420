import React from 'react';
import {Link} from '@inertiajs/inertia-react';
import ApplicationLogo from "../../../ComponentPackage/ApplicationLogo";

export default function Guest({children}) {
    return <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100 relative">
		<div className="absolute top-0 left-0 px-5 py-5 h-auto w-full">
			<img src="/images/new-icons/logo-main-full.png" className="float-left w-auto h-3" />
		</div>

        <div className="relative pt-10 pb-10 mt-6 w-full max-w-screen-xs">
            {children}
        </div>
		
		
		<div className="absolute bottom-0 left-0 px-5 py-5 font-semibold  w-full">
			Don’t have an account? <a href="" class="btn-link">Sign up today!</a>
		</div>
    </div>
}
