import React from 'react';
import {InertiaLink} from "@inertiajs/inertia-react";
import {Inertia} from "@inertiajs/inertia";
import useQueryString from "../../../Hooks/useQueryString";
import {Table} from "../../../ComponentPackage/Tables";
import Authenticated from "../../Admin/Layouts/Authenticated";
import PageTitle from "../../../ComponentPackage/PageTitle";
import {Section} from "../../../ComponentPackage/Sections";
import DeleteConfirmation from "../../../ComponentPackage/Modals/DeleteConfirmation";
import Button from "../../../ComponentPackage/Button";

const config = {
    columns: [
        {
            title: "Name",
            render: (user) => (
                <>
                    <InertiaLink
                        href={route('admin.users.show', [user.id])}
                        className="text-sm btn-link"
                    >{user.name}</InertiaLink>
                </>
            )
        },
        {
            title: "Email",
            render: user => (
                <span className="text-sm text-gray-500">{user.email}</span>
            )
        },
        {
            render: (user) => (
                <InertiaLink
                    href={route('admin.users.show', [user.id])}
                    className="text-sm  btn-link"
                >View</InertiaLink>
            )
        },
        {
            render: (user) => {
                return <DeleteConfirmation
                    isOpen={open}
                    message= {"Are you sure you want to delete?"}
                    title="Delete User"
                    onConfirm={() => Inertia.delete(route('admin.users.destroy', [user.id]), { preserveScroll: true })}
                />

            }
        }
    ]
};

const Index = ({ superUsers, auth }) => {
    const [searchValue, setSearchValue] = useQueryString("search", 'users')

    return (
        <Section header={{
            title: "All Users",
            onSearchChange: value => setSearchValue(value),
            searchValue: searchValue,
            searchPlaceholder: "Search users"
        }}>
            <div className="grid grid-cols-8 gap-8">
                <div className="col-span-8 sm:col-span-8">
                    <Table data={superUsers.data} meta={superUsers.meta} config={config} />
                </div>
            </div>
        </Section>
    );
};

Index.layout = page => (
    <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle
            title="Manage Users"
            actions={[
                props =>
                    <Button type="button" style="primary" onClick={() => Inertia.visit(route('admin.users.create'))} {...props}>Create User</Button>
            ]}
        />}
    />
);


export default Index;
