import {Section} from "../../../../ComponentPackage/Sections";
import {Table} from "../../../../ComponentPackage/Tables";
import useQueryString from "../../../../Hooks/useQueryString";
import moment from "moment";
import {InertiaLink} from "@inertiajs/inertia-react";


const config = {
    columns: [
        {
            title: "Locum",
            render: (locumRequest) => (
                <>
                    <span className="text-sm text-gray-500">{locumRequest?.locum?.data?.first_name} {locumRequest?.locum?.data?.last_name}</span>
                </>
            )
        },
        {
            title: "Availability",
            render: locumRequest => (
                <span className="text-sm text-gray-500">{locumRequest?.availability}</span>
            )
        },
        {
            title: "Date",
            render: locumRequest => (
                <span className="text-sm text-gray-500">{moment(locumRequest?.from).format('DD/MM/YYYY')}</span>
            )
        },
        {
            title: "Time",
            render: locumRequest => (
                <span className="text-sm text-gray-500">{moment(locumRequest?.from).format('HH:mm')} - {moment(locumRequest?.to).format('HH:mm')}</span>
            )
        },
        {
            render: (locumRequest) => (
                <InertiaLink
                    href={route('admin.locum-requests.show', [locumRequest.id])}
                    className="text-sm  btn-link"
                >View</InertiaLink>
            )
        }
    ]
};

const UpcomingBookings = ({upcomingBookings, locum}) => {

    const [searchValue, setSearchValue] = useQueryString("store-search", '')

    return (
        <Section header={{
            title: "All Upcomming Bookings",
            onSearchChange: value => setSearchValue(value),
            searchValue: searchValue,
            searchPlaceholder: "Search Locum Requests",
        }}>
			<div className="float-left w-full block mt-5 h-auto">
            <Table data={upcomingBookings.data} meta={upcomingBookings.meta} config={config} />
			</div>
        </Section>
    );
};


export {UpcomingBookings as default, config as storeBookingTable}
