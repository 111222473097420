import React, {Fragment, useState} from 'react';
import {Link} from '@inertiajs/inertia-react';
import {
    Bars3Icon,
} from '@heroicons/react/24/outline'
import NavigationItem from "../../Common/Components/NavigationItem";
import AuthContext from "../../../Context/authContext";

export default function Authenticated({auth: {user}, header, children}) {
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const navigation = [
        {name: 'Dashboard', href: route('store.dashboard'), current: route().current('/')},
        {name: 'Shifts', href: route('store.locumRequests.index'), current: route().current('/')},
        {name: 'Negotiations', href: route('store.negotiation.index'), current: route().current('/')},
        {name: 'Profile', href: route('store.profile.index'), current: route().current('/')},
        {name: 'Locums', href: route('store.locums.index'), current: route().current('/')},
        {name: 'Settings', href: route('store.settings.index'), current: route().current('/')},
    ].filter(item => item).map(item => ({...item, ...{classNames: 'block px-3 py-2 rounded-md text-base font-medium'}}))

    return (
        <AuthContext.Provider value={{user: user}}>
            <div>

				
			
				
                    <div className="absolute top-0 z-10 left-0 w-full h-auto px-5 py-5 flex justify-between items-center content-center header">
					
					<a href="/" className="logo-main">
							<img src="/images/new-icons/logo-main-full.png" />
						</a>
						
						<button
                            type="button"
                            className="ml-auto mobile-menu"
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <span className="bar top transition"></span>
							 <span className="bar transition"></span>
							  <span className="bar bottom transition"></span>
                        </button>
						
						{sidebarOpen && <div className="mobile-menu-open absolute w-full block h-full min-h-screen">
                            
                                <div className="float-left w-full h-full flex flex-wrap items-start content-start justify-start flex-col">
                                    {navigation.map((item) => (
                                        <NavigationItem item={item}/>
                                    ))}
                                    <Link href={route('store.logout')} method="post" className="logout danger w-full flex mt-auto justify-start items-center content-center" as="button">
										<i class="fal fa-unlock"></i>
                                        Log Out
                                    </Link>
                                </div>
                            
                        </div>}
                        
                    </div>
					
					
					
                    <main className="w-full px-5 pb-6 panel-main float-left w-full h-auto">
                       
                            {header && 
							
							<div className="float-left w-full block main-heading">
							<div className="text-5xl float-left w-full title font-normal mb-6">{header} </div></div>
                           }
							
                            <div className="float-left w-full block">
                                <main>{children}</main>
                            </div>
               
                    </main>
  
				
            </div>
        </AuthContext.Provider>
    )
}


