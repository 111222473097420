import Authenticated from "../Layouts/Authenticated";
import PageTitle from "../../../ComponentPackage/PageTitle";
import Form from "./Form";
import Button from "../../../ComponentPackage/Button";
import {Section} from "../../../ComponentPackage/Sections";
import {useForm} from "@inertiajs/inertia-react";

const Show = ({superUser}) => {

    const {data, setData, patch, processing, errors} = useForm({
        name: superUser.name,
        email: superUser.email,
    })


    function submit(e) {
        e.preventDefault();

        patch(route('admin.users.update', superUser.id), {preserveScroll: true})
    }

    return (
        <Section>
            <div>
                <Form data={data} errors={errors} processing={processing} setData={setData} user={superUser}>
                    <div className="col-span-6 sm:col-span-6">
                        <div className="flex items-center justify-end  mt-1">
                            <Button style="positive" processing={processing} onClick={e => submit(e)}>
                                Create
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </Section>
    )
}

Show.layout = page => (
    <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle title={`User Overview (${page.props.superUser.name})`}/>}
    />

)

export {Show as default}
