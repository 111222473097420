import {useEffect, useState} from "react";
import {objMap} from "../../../../utilities";
import Checkbox from "../../../../ComponentPackage/Inputs/Checkbox";
import DayRate from "./DayRate";

const DayRates = ({dayRates, setData, errors}) => {
    const [dayRatesValue, setDayRates] = useState(dayRates)

    useEffect(() => {
        setData(dayRatesValue)
    }, [dayRatesValue])

    return (
        <div className="grid grid-cols-1 gap-5 table-splitters">
            {Object.keys(dayRatesValue).map((day, i) =>
                <DayRate day={day} dayRate={dayRatesValue[day]} key={i} errors={errors}
                     setData={newData => setDayRates({...dayRatesValue, ...{[day]: newData}})} />
            )}
        </div>
    )
}

export {DayRates as default}
