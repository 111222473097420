import {useEffect, useMemo, useState} from "react";
import Select from "../../../ComponentPackage/Inputs/Select";
import AsyncSelect from "../../../ComponentPackage/Selects/AsyncSelect";
import YesOrNo from "../../../ComponentPackage/Inputs/YesOrNo";
import Checkbox from "../../../ComponentPackage/Inputs/Checkbox";
import InputMask from "../../../ComponentPackage/Inputs/InputMask";
import NumberMask from "../../../ComponentPackage/Inputs/NumberMask";
import Field from "../../../ComponentPackage/Inputs/Field";
import AddressAutocomplete from "../../../ComponentPackage/Address/AddressAutocomplete";
import DayRates from "./Components/DayRates";
import Equipment from "../../Store/Profile/Components/Equipment";


const formatStore = (store) => ({
    first_name: store?.first_name,
    last_name: store?.last_name,
    mobile: store?.mobile,
    email: store?.email,
    address: store?.address,
})

const Form = ({store, errors, setData, dayRates, children, isRegister = false}) => {

    const otherStoreSystem = store?.systems?.find(system => system.name == 'Other')

    const [company, setCompany] = useState(store?.company)
    const [name, setName] = useState(store?.name)
    const [number, setNumber] = useState(store?.number)
    const [telephone, setTelephone] = useState(store?.telephone)
    const [address, setAddress] = useState(store?.address)
    const [hasNotifications, setHasNotifications] = useState(store?.receive_email_booking_notification ?? 0)
    const [dayRatesValue, setDayRatesValue] = useState(dayRates ?? null)
    const [equipment, setEquipment] = useState([])
    const [contactName, setContactName] = useState(store?.contact_name)

    const [systems, setSystems] = useState(store?.systems?.map(system => ({...system, ...{value: system.id}})))
    const [otherSystem, setOtherSystem] = useState(otherStoreSystem?.pivot?.other)

    useEffect(() => {

        setData({
            ...{
                company_id: company?.id,
                receive_email_booking_notification: hasNotifications,
                day_rates: dayRatesValue,
                contact_name: contactName,
                systems: systems?.map(system => system.value),
                other_system: otherSystem
            }, ...{name, number, telephone, address, equipment}
        })

    }, [equipment, company, name, number, telephone, address, hasNotifications, dayRatesValue, contactName, systems, otherSystem])

    Object.filter = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate));

    const addressErrors = useMemo(() => {
        const filteredRaw = Object.filter(errors ?? {}, ([name, message]) => name.includes('store.address'))

        const filtered = {}

        Object.keys(filteredRaw).forEach(function (name, value, array) {
            filtered[name.replace('store.address.', '')] = errors[name];
        })

        return filtered

    }, [errors])

    return (<>
            <div className="grid grid-cols-6 gap-5 float-left clear-both w-full mt-5 admin-no-margin">
                <div className="col-span-6 md:col-span-3">
                    <Field label="Company *" name="company" error={errors?.['store.company']}>
                        <AsyncSelect allowNullSearch={true} valueKey="id" labelKey="name" value={company}
                                     onChange={(name, value, option) => setCompany(option)}
                                     routeName="api.companies.index"/>
                    </Field>
                </div>
                <div className="col-span-6 md:col-span-3">
                    <Field label="Store Name *" name="name" value={name} onChange={(name, value) => setName(value)}
                           error={errors?.['store.name']}/>
                </div>
                <div className="col-span-6 md:col-span-3">
                    <Field label="Store Number *" name="number" value={number}
                           onChange={(name, value) => setNumber(value)}
                           error={errors?.['store.number']}/>
                </div>

                <div className="col-span-6 md:col-span-3">
                    <Field label="Store Telephone *" name="telephone" value={telephone}
                           onChange={(name, value) => setTelephone(value)}
                           error={errors?.['store.telephone']}/>
                </div>

                <div className="col-span-6 md:col-span-3">
                    <Field label="Store Contact Name *" name="contact_name" value={contactName}
                           onChange={(name, value) => setContactName(value)}
                           error={errors?.['store.contact_name']}/>
                </div>

                {!isRegister && <>
                    <div className="col-span-3">
                        <Field label="System(s) *" name="systems" value={systems}
                               onChange={(name, value, option) => setSystems(option)}
                               error={errors?.systems}>
                            <AsyncSelect allowNullSearch={true} valueKey="id" labelKey="name"
                                         value={systems} onChange={(name, value, option) => setSystems(option)}
                                         routeName="api.systems.index" multiple={true}/>
                        </Field>
                    </div>

                    {systems?.find(system => system.name == 'Other') && <div className="col-span-3">
                        <Field label="Other System *" name="other_system" value={otherSystem}
                               onChange={(name, value) => setOtherSystem(value)}
                               error={errors?.other_system}/>
                    </div>}
                </>}
            </div>


            <div className="float-left w-full block mt-5 float-left clear-both w-full">
                <AddressAutocomplete setData={setAddress} errors={addressErrors} address={address}/>
            </div>

            <div className="grid grid-cols-1 gap-5 float-left clear-both w-full mt-5 mb-4">
                <div className="col-span-1">
                    <h3 className="title-row ">Equipment</h3>

                </div>
            </div>

            <Equipment equipment={store} setData={setEquipment} errors={errors}/>

            <div className="float-left w-full block  clear-both mt-5 mb-4">

                <h3 className="title-row  float-left w-full block">Day Rates</h3>
            </div>

            <div className="float-left w-full block  clear-both day-rates-admin">
                {dayRates && <DayRates dayRates={dayRates} errors={errors} setData={setDayRatesValue}/>}
            </div>


            <div className="float-left w-full block mt-5 mb-5 clear-both admin-form-amends">
                {children}
            </div>

            <div className="grid grid-cols-1 gap-5 float-left clear-both w-full mb-5">
                <div className="col-span-1 align-fields admin-form-amends-receive">
                    <Field label="Receive Email Booking Notifications"
                           error={errors.receive_email_booking_notifications}>
                        <Checkbox name="has_notifications" value={1} checked={!!hasNotifications}
                                  handleChange={(checked) => setHasNotifications(checked ? 1 : 0)}/>
                    </Field>
                </div>


            </div>
        </>)
}

export {Form as default}

