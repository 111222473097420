
import Checkbox from "../../../../ComponentPackage/Inputs/Checkbox";
import NumberMask from "../../../../ComponentPackage/Inputs/NumberMask";
import Field from "../../../../ComponentPackage/Inputs/Field";
import {useEffect, useState, useMemo} from "react";

const Equipment = ({setData, equipment, errors}) => {



    const [phoropter, setPhoropter] = useState(equipment?.phoropter)
    const [trialFrame, setTrialFrame] = useState(equipment?.trial_frame)
    const [oct, setOCT] = useState(equipment?.oct)
    const [contactLenses, setContactLenses] = useState(equipment?.contact_lenses)
    const [testTime, setTestTime] = useState(equipment?.test_time)

    useEffect(() => {
        setData({...{
                trial_frame: trialFrame,
                contact_lenses: contactLenses,
                test_time: testTime,
            }, ...{oct, phoropter}})
    }, [phoropter, trialFrame, oct, contactLenses, testTime])

    const icons = useMemo(() => {
        const iconClassNames = 'w-6 h-6 float-left mr-4'
        return {
            'phoropter' : <img src="/images/new-icons/photoraptor.png" />,
            'trialFrame': <img src="/images/new-icons/trial-frame.png" />,
            'oct': <img src="/images/new-icons/OCT.png" />,
            'contactLenses': <img src="/images/new-icons/contact-lens.png" />
        }
    }, [])

    const equipmentOptions = [
        {label: 'Phoropter', setData: setPhoropter, value: phoropter, icon: icons.phoropter},
        {label: 'Trial Frame', setData: setTrialFrame, value: trialFrame, icon: icons.trialFrame},
        {label: 'OCT', setData: setOCT, value: oct, icon: icons.oct},
        {label: 'Contact Lenses', setData: setContactLenses, value: contactLenses, icon: icons.contactLenses},
    ]

    return <>
        <div className="grid grid-cols-8 gap-5 float-left clear-both w-full">
            {equipmentOptions.map(option => <div className="col-span-4 md:col-span-2 flex relative">
				<div className="flex justify-start items-center content-center block-icon w-full">
                <div className='equipmentIcon flex-none'>{option.icon}</div>
                <div className='equipmentLabel flex-auto' >{option.label}</div>
                <div className='equipmentCheckbox flex-none toggle-checkbox'>
                   <Checkbox name={option.label} value={option.value} checked={!!option.value}
                              handleChange={(checked) => option.setData(checked ? 1 : 0)}/>
                </div>
				</div>
            </div>)}
            <div className="col-span-8 md:col-span-4 ">
				<div className="testing-time flex flex-wrap justify-start items-center content-center">
                <Field label="Test Time" error={errors?.test_time} >
                    <NumberMask name="test_time" value={testTime} onChange={(name, value) => setTestTime(value)} type="minutes" />
                </Field>
				</div>
            </div>
        </div>
    </>
}

export {Equipment as default}
