
import NegotiationPill from "../../../Store/LocumRequests/Negotiations/Components/NegotiationPill";
import Negotiation from "../../../Store/LocumRequests/Negotiations/Components/Negotiation";
import Audit from "../../../Store/LocumRequests/Negotiations/Components/Audit";
import {useState} from "react";


const Negotiations = ({locumRequest, negotiations}) => {

    const [isViewOption, setViewOpen] = useState(false)
    const [isAuditOpen, setAuditOpen] = useState(false)
    const [negotiation, setNegotiation] = useState({})


    return (<>
        {negotiations?.data?.map(negotiation => <NegotiationPill key={negotiation.id} {...{locumRequest, setNegotiation, setViewOpen, negotiation, setAuditOpen}} />)}
        <Negotiation {...{locumRequest, negotiation, setNegotiation}} disabled={true} isOpen={isViewOption} setOpen={setViewOpen} />
        <Audit {...{locumRequest, negotiation, setNegotiation}} isOpen={isAuditOpen} setOpen={setAuditOpen} />
    </>)

}

export {Negotiations as default}
