import TypeIcon from "./TypeIcon";
import {useCallback, useEffect, useMemo, useState} from "react";
import CustomTrustpoolIcon from "./CustomTrustpoolIcon";

const SendType = ({type, customTrustpool, setType, setCustomPool, locumRequest, disabled = false}) => {

    const icons = useMemo(() => {
        return {
            'all' : <span></span>,
            'custom_trustpool': <span></span>,
            'trustpool': <span></span>
        }

    }, [])

    const [trustpoolLocums, setTrustpoolLocums] = useState([])

    const [sendType, setSendType] = useState(type)

    useEffect(() => {
        setType(sendType)
        setCustomPool(trustpoolLocums)
    }, [sendType, trustpoolLocums])

    useEffect(() => {
        setSendType(locumRequest?.send_type ?? 'all')
        setTrustpoolLocums(locumRequest?.trustpool?.data ?? [])
    }, [locumRequest])


    const handleCustomTrustpool = useCallback((selectedLocums) => {

        setSendType('custom_trustpool')
        setTrustpoolLocums(selectedLocums)

    }, [])


    return (
        <div className="grid grid-cols-3 gap-3 w-full send-to">
			<div className="col-span-3">
			<h4 className="w-full block title float-left">Send To</h4>
			</div>
            <div className="col-span-1">
				<div className="send-to-panel float-left w-full block all-locum title">
                <TypeIcon type='all' label="All" setType={setSendType} disabled={disabled} active={sendType == 'all'} icon={icons.all} />
				</div>
            </div>
           
            <div className="col-span-1">
				<div className="send-to-panel float-left w-full block custom-trustpool title">
                <TypeIcon disabled={disabled} type='trustpool' label="Trustpool" setType={setSendType} active={sendType == 'trustpool'} icon={icons.trustpool} />
				</div>
            </div>
			
			 <div className="col-span-1">
				<div className="send-to-panel float-left w-full block trustpool title">
                <CustomTrustpoolIcon  disabled={disabled} active={sendType == 'custom_trustpool'} trustpoolLocums={trustpoolLocums} icon={icons.custom_trustpool} setCustomTrustpool={handleCustomTrustpool}  />
				</div>
            </div>
        </div>
    )
}

export {SendType as default}
