const TabIcon = ({className, label, number, icon, iconClassName = 'grid place-items-center tabIcon'}) => {
    return <div className={className}>
        {number && <span className='number'>{number}</span>}
        <div className={iconClassName}>
            {icon}
        </div>
        <span className='label'>{label}</span>

    </div>
}

export {TabIcon as default}
