import React, { useEffect, useRef } from 'react';
import ExclamationCircleIcon from "@heroicons/react/20/solid/ExclamationCircleIcon";
import {classNames} from "../../utilities";

export default function Input({
    type = 'text',
    name,
    value,
    className,
    autoComplete,
    required,
    isFocused,
    error = "",
    placeholder = "",
    onChange,
    RenderIconLeft,
    disabled = false
}) {
    const input = useRef();

    useEffect(() => {
        if (isFocused) {
            input.current.focus();
        }
    }, []);

    const classes = error.length > 0 ? 'pr-10 error' :
        ' transition';

    let inputRender;

    switch(type) {
        case 'yes_no':
            const options = [{label: "Yes", value: 1}, {label: "No", value: 0}];
            inputRender = <div className="radio">
                {options.map(({label: input_label, value: input_value}) => (
					<div className="float-left flex justify-start align-center items-center radio-fix mr-6 relative">

                        <input
                            type="radio"
                            value={input_value}
                            onChange={onChange}
                            checked={parseInt(value) === parseInt(input_value)}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
					 <label className="" key={input_value}>
                        <span className="">
                            {input_label}
                        </span>
                    </label>
					</div>
                ))}
            </div>
            break;
        case 'textarea':
            inputRender = <textarea
                name={name}
                value={value}
                className={classNames(
                    classes,
                    ` block w-full focus:ring focus:ring-opacity-50 `,
                    className,
                    (RenderIconLeft && ` pl-10 `)
                )}
                ref={input}
                autoComplete={autoComplete}
                required={required}
                placeholder={placeholder}
                onChange={onChange}
                disabled={disabled}
            />
            break;
        default:
            inputRender = <input
                type={type}
                name={name}
                value={value}
                className={classNames(
                    classes,
                    `form-input`,
                    className,
                    (RenderIconLeft && ``)
                )}
                ref={input}
                autoComplete={autoComplete}
                required={required}
                placeholder={placeholder}
                onChange={onChange}
                disabled={disabled}
            />
            break;
    }

    return (
        <React.Fragment>
            <div className="relative block clear-both float-left w-full">
                {
                    RenderIconLeft &&
                    <div className="absolute render-icon right-4 search pointer-events-none error-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"/>
                        </svg>
                    </div>
                }

                { inputRender }

                {
                    error.length > 0 &&
                    <div className="absolute render-icon right-4  pointer-events-none error-icon">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                }
            </div>
            {
                error.length > 0 &&
                <p className="mt-2 text-sm text-red-600 clear-both float-left w-full error" id="email-error">{ error }</p>
            }
        </React.Fragment>
    );
}
