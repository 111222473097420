import {useForm} from "@inertiajs/inertia-react";
import {Section} from "../../../../ComponentPackage/Sections";
import Form from "../Form";
import Button from "../../../../ComponentPackage/Button";
import {convertArrayToObject} from "../../../../utilities";
import moment from "moment";


const formatDayRates = (dayRates) => {
    return convertArrayToObject(dayRates.sort((dayRate) => moment(dayRate.pivot.day, 'dddd').format('d')).map(dayRate => {
        return {
            day: dayRate.pivot.day,
            start: dayRate.pivot.start,
            finish: dayRate.pivot.finish,
            rate: dayRate.rate,
            break_time: dayRate.pivot.break_time,
            active: !(!!dayRate.deleted_at),
        }
    }), 'day')
}

const formatStore = (store, dayRates) => {
    const pickedValues = (({name, number, telephone, oct}) => ({name, number, telephone, oct}))(store)

    const otherValues = {
        company_id: store?.company?.id ?? null,
        address: store?.address,
        equipment: store?.equipments,
        contact_lenses: store?.contact_lenses,
        test_time: store?.test_time,
        receive_email_booking_notification: store?.receive_email_booking_notification ?? 0,
        pay_rates: formatDayRates(dayRates)
    }

    return {...pickedValues, ...otherValues}
}

const Profile = ({store, dayRates}) => {

    const {processing, setData, errors, patch} = useForm(formatStore(store, dayRates))

    function submit(e) {
        e.preventDefault();

        patch(route('admin.stores.update', [store.id]), {preserveScroll: true})

    }


    return (
        <Section>
            <Form store={store} errors={errors} setData={setData} dayRates={formatDayRates(dayRates)}>
                <div className="col-span-6 sm:col-span-6">
                    <div className="flex items-center justify-end  mt-6">
                        <Button style="positive" processing={processing} onClick={e => submit(e)}>
                            Update
                        </Button>
                    </div>
                </div>
            </Form>
        </Section>
    )
}

export {Profile as default, formatStore, formatDayRates}
