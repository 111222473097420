import {Link} from "@inertiajs/inertia-react";

const NavItem = ({item}) => {
    return (
        <div className=''>
            <Link key={item.name} href={item.href}>{item.name}</Link>
        </div>
    )
}

export {NavItem as default}
