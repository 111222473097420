import {useMemo} from "react";
import {Inertia} from "@inertiajs/inertia";
import Authenticated from "../Layouts/Authenticated";
import PageTitle from "../../../ComponentPackage/PageTitle";

const Index = ({totalUnreadComments, totalNegotiations}) => {



    const icons = useMemo(() => {
        const iconClassNames = ''
        return {
            'shifts' : <img src="/images/new-icons/shifts.png"/>,
            'negotiations': <img src="/images/new-icons/negotiations.png"/>,
            'messages': <img src="/images/new-icons/messages.png"/>,
            'locums': <img src="/images/new-icons/locums.png"/>,
            'profile': <img src="/images/new-icons/profile.png"/>

        }
    }, [])

    console.log(!!totalUnreadComments)

    const navItems = useMemo(() => [
        {name: 'Shifts', route: route('store.locumRequests.index'), icon: icons.shifts},
        {name: 'Negotiations', route: route('store.negotiation.index'), icon: icons.negotiations, number: totalNegotiations},
        {name: `Messages`, route: route('store.locumRequests.index', {tab: 'booked'}), icon: icons.messages, number: totalUnreadComments},
        {name: 'Locums', route: route('store.locums.index'), icon: icons.locums},
        {name: 'Profile', route: route('store.profile.index'), icon: icons.profile}
    ], [icons])

    return (<ul role="list" className="panel-list float-left w-full d-block h-auto">
        {navItems.map((item) => (
            <li key={item.name}
                className="bg-white panel-inner w-full flex title relative justify-start items-center content-center flex-wrap shadow mb-2 transition"
                onClick={e => Inertia.visit(item.route)}
            >
                <div className='dashboard-icon'>{item.icon}</div>
                <div className='dashboard-text font-bold float-left'>{item.name}</div>
                {!!item.number && <span className='number ml-1'>{item.number}</span>}

				<i class="fal fa-long-arrow-right ml-auto"></i>
            </li>
        ))}
    </ul>)
}

Index.layout = page =>
    <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle title='Dashboard'/>}
    />

export {Index as default}
