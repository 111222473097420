import LocumPill from "../Components/LocumPill";
import useQueryString from "../../../../Hooks/useQueryString";
import Input from "../../../../ComponentPackage/Inputs/Input";
import MagnifyingGlassCircleIcon from "@heroicons/react/20/solid/MagnifyingGlassCircleIcon";
import {useCallback, useState} from "react";
import AddLocum from "../Modals/AddLocum";
import RemoveLocum from "../Modals/RemoveLocum";
import SearchBar from "../../../../Components/SearchBar";

const Locums = ({locums, type}) => {

    const [searchValue, setSearchValue] = useQueryString("search", route().params?.['search'])

    const [locum, setLocum] = useState()

    const [isRemoveLocumModalOpen, setRemoveLocumModal] = useState(false)
    const [isAddLocumModalOpen, setAddLocumModal] = useState(false)

    const setAction = useCallback((locum, type) => {

        if(type == 'remove') {
            setRemoveLocumModal(!!locum)
        } else if (type == 'add') {
            setAddLocumModal(!!locum)
        }

        setLocum(locum)

    }, [])

    return (<>
        	<div className="w-full block mb-6">
            <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} name='section_search' />
			</div>
			<div className="w-full block float-left full-width-split">
            {locums.map(locum => <LocumPill type={type} setAction={setAction} locum={locum} key={locum.id} />)}
			</div>
            <AddLocum locum={locum} setOpen={setAddLocumModal} isOpen={isAddLocumModalOpen} />
            <RemoveLocum locum={locum} setOpen={setRemoveLocumModal} isOpen={isRemoveLocumModalOpen} />
        
    </>)
}

export {Locums as default}
