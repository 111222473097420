import React, {useState} from 'react';
import MagnifyingGlassCircleIcon from "@heroicons/react/20/solid/MagnifyingGlassCircleIcon";
import Input from "../../ComponentPackage/Inputs/Input";

const SectionHeaderElement = ({title, onSearchChange, searchValue = "", searchPlaceholder = "", children}) => {

    const handleSearchChange = e => onSearchChange(e.target.value);

    return (
        <div className="mb flex  justify-between items-center content-center section-header">
            {title && <h3 className="mr-auto hidden">{title}</h3>}
            {
                children &&
                <div className="flex  rounded-md shadow-sm float-right ml-auto ">
                    {children}
                </div>
            }

            {
                onSearchChange &&
                <div className="flex ml-auto pl-4">
                    <div className="relative flex-grow focus-within:z-10 search form-group">
                        <Input
                            type="text"
                            defaultValue={searchValue}
                            placeholder={searchPlaceholder}
                            name="section_search"
                            onChange={handleSearchChange}
                            RenderIconLeft={MagnifyingGlassCircleIcon}
                        />
                    </div>
                </div>
            }
        </div>
    );
}

export default SectionHeaderElement;
