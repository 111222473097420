import React from 'react';
import {InertiaLink} from "@inertiajs/inertia-react";
import {Inertia} from "@inertiajs/inertia";
import useQueryString from "../../../Hooks/useQueryString";
import {Table} from "../../../ComponentPackage/Tables";
import Authenticated from "../../Admin/Layouts/Authenticated";
import PageTitle from "../../../ComponentPackage/PageTitle";
import {Section} from "../../../ComponentPackage/Sections";
import DeleteConfirmation from "../../../ComponentPackage/Modals/DeleteConfirmation";

const config = {
    columns: [
        {
            title: "Name",
            render: (locum) => (
                <>
                    <InertiaLink
                        href={route('admin.locums.show', [locum.id])}
                        className="text-sm btn-link"
                    >{locum.first_name} {locum.last_name}</InertiaLink>
                </>
            )
        },
        {
            title: "Email",
            render: locum => (
                <span className="text-sm text-gray-500">{locum.email}</span>
            )
        },
        {
            title: "Mobile",
            render: locum => (
                <span className="text-sm text-gray-500">{locum.mobile}</span>
            )
        },
        {
            render: (locum) => (
                <InertiaLink
                    href={route('admin.locums.show', [locum.id])}
                    className="text-sm  btn-link"
                >View</InertiaLink>
            )
        },
        {
            render: (locum) => {
                return <DeleteConfirmation
                    isOpen={open}
                    message= {"Are you sure you want to delete?"}
                    title="Delete Locum"
                    onConfirm={() => Inertia.delete(route('admin.locums.destroy', [locum.id]), { preserveScroll: true })}
                />

            }
        }
    ]
};

const Index = ({ locums, auth }) => {
    const [searchValue, setSearchValue] = useQueryString("search", 'locums')

    return (
        <Section header={{
            title: "All Locums",
            onSearchChange: value => setSearchValue(value),
            searchValue: searchValue,
            searchPlaceholder: "Search locums"
        }}>
            <div className="float-left w-full block mt-5 h-auto">
                    <Table data={locums.data} meta={locums.meta} config={config} />
                </div>
        </Section>
    );
};

Index.layout = page => (
    <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle
            title="Manage Locums"
        />}
    />
);


export default Index;
