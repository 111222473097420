import Profile from "./Tabs/Profile";
import Tabs from "../../../ComponentPackage/Containers/Tabs";
import WorkPreferences from "./Tabs/WorkPreferences";
import {useEffect, useState} from "react";
import PreviousBookings from "./Tabs/PreviousBookings";
import UpcomingBookings from "./Tabs/UpcomingBookings";
import CancelledBookings from "./Tabs/CancelledBookings";

const Form = ({locum, tab = 'profile', processing, companies, documentTypes, previousBookings, upcomingBookings, cancelledBookings}) => {

    const tabs = [
        { label: 'Profile', href: `/locums/${locum.id}`, name: 'profile', Render: <Profile locum={locum} documentTypes={documentTypes} /> },
        { label: 'Work Preferences', href: `/locums/${locum.id}/work-preferences`, name: 'work-preferences', Render: <WorkPreferences locum={locum} companies={companies} /> },
        { label: 'Upcoming Bookings', href: `/locums/${locum.id}/upcoming-bookings`, name: 'upcoming-bookings', Render: <UpcomingBookings locum={locum} upcomingBookings={upcomingBookings} /> },
        { label: 'Previous Bookings', href: `/locums/${locum.id}/previous-bookings`, name: 'previous-bookings', Render: <PreviousBookings locum={locum} previousBookings={previousBookings} /> },
        { label: 'Cancelled Bookings', href: `/locums/${locum.id}/cancelled-bookings`, name: 'cancelled-bookings', Render: <CancelledBookings locum={locum} cancelledBookings={cancelledBookings} /> },
    ];

    return (
        <>
            <Tabs tabs={tabs} activeTabName={tab ?? 'profile'} />
        </>
    )
}

export default Form
