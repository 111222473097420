import React, {useState} from 'react';
import {InertiaLink} from "@inertiajs/inertia-react";
import {Inertia} from "@inertiajs/inertia";
import useQueryString from "../../../Hooks/useQueryString";
import {Table} from "../../../ComponentPackage/Tables";
import Authenticated from "../../Admin/Layouts/Authenticated";
import PageTitle from "../../../ComponentPackage/PageTitle";
import {Section} from "../../../ComponentPackage/Sections";
import DeleteConfirmation from "../../../ComponentPackage/Modals/DeleteConfirmation";
import moment from "moment";
import Field from "../../../ComponentPackage/Inputs/Field";
import Datepicker from "../../../ComponentPackage/Inputs/Datepicker";
import AsyncSelect from "../../../ComponentPackage/Selects/AsyncSelect";

const config = {
    columns: [
        {
            title: "Store",
            render: (locumRequest) => (
                <>
                    <span className="text-sm text-gray-500">{locumRequest?.store?.data?.company} {locumRequest?.store?.data?.name && `(${locumRequest?.store?.data?.name})`}</span>
                </>
            )
        },
        {
            title: "Locum",
            render: locumRequest => (
                <span className="text-sm text-gray-500">{locumRequest?.locum?.data?.first_name} {locumRequest?.locum?.data?.last_name}</span>
            )
        },
        {
            title: "Location",
            render: locumRequest => (
                <span className="text-sm text-gray-500">{locumRequest?.store?.data?.address}</span>
            )
        },
        {
            title: "Availability",
            render: locumRequest => (
                <span className="text-sm text-gray-500">{locumRequest?.availability}</span>
            )
        },
        {
            title: "Date",
            render: locumRequest => (
                <span className="text-sm text-gray-500">{moment(locumRequest?.date).format('DD/MM/YYYY')}</span>
            )
        },
        {
            title: "Time",
            render: locumRequest => (
                <span className="text-sm text-gray-500">{moment(locumRequest.start, 'HH:mm:ss').format('HH:mm')} - {moment(locumRequest.finish, 'HH:mm:ss').format('HH:mm')}</span>
            )
        },
        {
            render: (locumRequest) => (
                <InertiaLink
                    href={route('admin.locum-requests.show', [locumRequest.id])}
                    className="text-sm  btn-link"
                >View</InertiaLink>
            )
        }
    ]
};

const Index = ({ locumRequests, auth }) => {
    const [searchValue, setSearchValue] = useQueryString("search", 'locumRequests')
    const [dateRangeValue, setDateRangeValue] = useQueryString("date", `${moment().format('DD/MM/YYYY')} - ${moment().add(1, 'month').format('DD/MM/YYYY')}`)
    const [companyValue, setCompanyValue] = useQueryString("company", '')
    const [addressValue, setAddressValue] = useQueryString("address", '')

    const [dateRange, setDateRange] = useState(dateRangeValue)


    const handleDateRangeChange = (name, value) => {
        setDateRange(value)
        const dates = value.split(' - ')

        setDateRangeValue(dates.length === 2 ? value : '')
    }

    return (
        <Section header={{
            title: "All Locum Requests",
            onSearchChange: value => setSearchValue(value),
            searchValue: searchValue,
            searchPlaceholder: "Search Locum Requests"
        }}>
			
			<div className="grid grid-cols-6 gap-5">
				<div className="col-span-6 md:col-span-2">
					<Field label="Date Range">
                        <Datepicker onChange={handleDateRangeChange} value={dateRange} type="range"/>
                    </Field>
				</div>
				
				<div className="col-span-6 md:col-span-2">
					 <Field label="Company">
                        <AsyncSelect routeName='api.companies.index' onChange={(name, value) => setCompanyValue(value)}
                                     value={companyValue} selectParams={{company: companyValue}} selectRouteName="api.companies.show"
                                     name="company_id" allowNullSearch={true} placeholder="Select Company"/>
                    </Field>
				</div>
				
				<div className="col-span-6 md:col-span-2">
					<Field label="Address" name="address" value={addressValue} onChange={(name, value) => setAddressValue(value)}/>
				</div>
			</div>
			
			<div className="float-left w-full block mt-5">
				<Table data={locumRequests.data} meta={locumRequests.meta} config={config} />
			</div>
			
            
        </Section>
    );
};

Index.layout = page => (
    <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle
            title="Manage Locum Requests"
        />}
    />
);


export {Index as default, config as locumRequestConfig};
