import React, {Fragment, useState} from 'react';
import {Link} from '@inertiajs/inertia-react';
import {
    Bars3Icon,
    HomeIcon,
    XMarkIcon,
    BuildingStorefrontIcon,
    UserIcon,
    EyeIcon,
    DocumentTextIcon
} from '@heroicons/react/24/outline'
import NavigationItem from "../../Common/Components/NavigationItem";
import AuthContext from "../../../Context/authContext";

export default function Authenticated({auth: {user}, header, children}) {
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const navigation = [
        {name: 'Dashboard', href: route('admin.dashboard'), icon: HomeIcon, current: route().current('/')},
        {name: 'Users', href: route('admin.users.index'), icon: UserIcon, current: route().current('/')},
        {name: 'Locums', href: route('admin.locums.index'), icon: EyeIcon, current: route().current('/')},
        {name: 'Stores', href: route('admin.stores.index'), icon: BuildingStorefrontIcon, current: route().current('/')},
        {name: 'Locum Requests', href: route('admin.locum-requests.index'), icon: DocumentTextIcon, current: route().current('/')},
    ].filter(item => item)

    return (
        <AuthContext.Provider value={{user: user}}>
            {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
            <div>
                {/* Static sidebar for desktop */}
                <div className="fixed sidebar-nav">
                    
                    <div className="flex min-h-0 flex-1 flex-col bg-white shadow main-nav">
                        <div className="flex flex-1 flex-col pt-6 pb-6">
                            <div className="flex flex-shrink-0 items-left">
                                <div className="flex flex-shrink-0 items-left px-5">
                                    <img src="/images/logo.jpg" alt="main logo" className="w-48 h-auto block logo-main float-left" />
                                </div>
                            </div>
                            <nav className="mt-8 flex-1 space-y-1">
                                {navigation.map((item) => (
                                    <NavigationItem item={item}/>
                                ))}
                            </nav>
                        </div>
                        <div className="flex flex-shrink-0  px-5 py-4 logout-link">
                            <Link href={route('admin.logout')} method="post" as="button">
								<i class="fal fa-unlock-alt"></i>
                                Log Out
                            </Link>
                        </div>
                    </div>
                </div> 
                <div className="flex flex-1 flex-col sidebar-main float-left w-full ">
                     
                    <main className="flex-1">
                        <div className="float-left w-full block h-auto">
                            {header && <div className="w-full block float-left h-auto admin-header px-6 py-6">
                                <div className="w-full-block flost-left">
                                    <div className=" justify-start flex flex-wrap">{header}</div>
                                </div>
                            </div>}
                            <div className="w-full block float-left h-auto px-6 pb-6">
                                <main>{children}</main>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </AuthContext.Provider>
    )
}


