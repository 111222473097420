import Tabs from "../../../ComponentPackage/Containers/Tabs";
import {Section} from "../../../ComponentPackage/Sections";
import Overview from "../../Admin/LocumRequests/Tabs/Overview";
import Negotiations from "../../Admin/LocumRequests/Tabs/Negotiations";
import PageTitle from "../../../ComponentPackage/PageTitle";
import Authenticated from "../Layouts/Authenticated";
import {useMemo, useState} from "react";
import Locums from "./Tabs/Locums";
import TabIcon from "../LocumRequests/Icons/TabIcon";
import LocumRequest from "../LocumRequests/Components/LocumRequest";

const Index = ({tab, locums, trustpoolTotal}) => {

    const icons = useMemo(() => {
        return {
            
        }
    }, [])

    const tabs = [
        { label: <TabIcon iconClassName='locumTab' label='All Locums' icon={icons.locum} className='locums' />, href: `/locums`,
            name: 'locums', Render: <Locums locums={locums.data}/>, classNames: 'iconTab'},
        { label: <TabIcon iconClassName='locumTab' label='Trustpool' icon={icons.trustpool} className='trustpool' number={trustpoolTotal} />, href: `/locums/trustpool`,
            name: 'trustpool', Render: <Locums type='trustpool' locums={locums.data}/>, classNames: 'iconTab' },
    ];


    return (<>
        <Section >
            <Tabs tabs={tabs} activeTabName={tab ?? 'locums'} />
        </Section>
    </>)
}

Index.layout = page => (
    <Authenticated
        children={page}
        auth={page.props.auth}
        header={<PageTitle
            title="Locums"
        />}
    />
);

export {Index as default}
