import {Section} from "../../../../ComponentPackage/Sections";
import Authenticated from "../../Layouts/Authenticated";
import PageTitle from "../../../../ComponentPackage/PageTitle";
import Button from "../../../../ComponentPackage/Button";
import {Inertia} from "@inertiajs/inertia";
import route from "ziggy-js/src/js";
import Guest from "../../Layouts/Guest";
import {router} from "@inertiajs/react";

const NewSubscription = ({}) => {
    return (
        <Section>
            <h3>There is no subscription on your store.</h3>
            <p>Use the link before to process your subscription</p>
            <Button type='button' onClick={e => router.visit(route('store.settings.billing.new-subscription.redirect'))}>Continue to Payment</Button>
        </Section>
    )
}

NewSubscription.layout = page => <Authenticated
    children={page}
    auth={page.props.auth}
    header={<PageTitle title='Subscription Required'/>}
/>

export {NewSubscription as default}
