import React, {Fragment, useCallback, useMemo, useRef, useState} from "react";
import ReactAsyncSelect from "react-select/async";
import ReactAsyncSelectCreateable from 'react-select/async-creatable';
import {classNames} from "../../utilities";
const axios = require('axios');

const AsyncSelect = ({
    routeName, routeParams, valueKey = 'id',
    labelGenerate, multiple = false, error, onChange,
    name, value, queryName = 'query', onQueryFail, onQuerySuccess,
    placeholder, formatData, allowNullSearch = false, createable = false,
    innerRef, disabled, selectParams, selectRouteName, className
}) => {
    const [inputValue, setInputValue] = useState('')

    const [options, setOptions] = useState([])

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        setInputValue(inputValue);
    };

    const generateLabel = labelGenerate ?? useCallback((data) => (data.name), [])

    const inputRef = innerRef ?? useRef(null)

    const handleDefaultValue = () => {

        if (Array.isArray(value)) {
            return value.map(item => {

                let matched = options.filter((optionItem) => (String(optionItem[valueKey]) === String(item)))

                if(matched.length) {
                    return {
                        value: matched[0][valueKey],
                        label: generateLabel(matched[0])
                    }
                }

                return {
                    value: item[valueKey],
                    label: generateLabel(item)
                };
            })
        }

        if (value !== null && typeof value === 'object' && value.hasOwnProperty(valueKey)) {
            return {
                value: value[valueKey],
                label: generateLabel(value)
            };
        }

        let matched = options.filter((item) => (String(item[valueKey]) === String(value)))

        if(matched.length) {
            return {
                value: matched[0][valueKey],
                label: generateLabel(matched[0])
            }
        }

        if (value !== '' && typeof value === 'string' && selectRouteName) {
            axios.get(route(selectRouteName, {...routeParams, ...{[queryName]: inputValue}, ...selectParams})).then((data) => (data.data))
                .then((data) => {

                    data.data && inputRef.current.setValue({
                        value: data.data[valueKey],
                        label: generateLabel(data.data)
                    }, 'select-option')

                    if (data && data[valueKey] && generateLabel(data)) {
                        inputRef.current.setValue({
                            value: data[valueKey],
                            label: generateLabel(data)
                        }, 'select-option')
                    }
                })
        }

        return value;
    };


    const handleOnChange = (options, action) => {

        if (multiple)
            onChange(action.name, options.map(option => option.value), options)
        else
            onChange(action.name, options ? options.value : '', options)
    };

    const loadOptions = (inputValue, callback) => {

        (inputValue || allowNullSearch) && axios.get(route(routeName, {...routeParams, ...{[queryName]: inputValue}}))
            .then((data) => (data.data))
            .then((data) => {
                if (data.hasOwnProperty('data'))
                    data = data.data;

                data = formatData ? formatData(data) : data


                if (!Array.isArray(data)) {
                    callback([])
                    onQueryFail && onQueryFail(data)
                    return true
                }

                onQuerySuccess && onQuerySuccess(data)

                let options = data.map(element => ({...element, ...{value: element[valueKey], label: generateLabel(element)}}))

                setOptions(options)

                callback(options)

            })

        !inputValue && callback([])
    }


    return (
        <>
            {
                createable ?
                    (
                        <Fragment>
                            <ReactAsyncSelectCreateable
                                cacheOptions
                                loadOptions={loadOptions}
                                classNamePrefix="react-select "
                                defaultOptions
                                name={name}
                                placeholder={placeholder}
                                isMulti={multiple}
                                //menuIsOpen={true}
                                defaultValue={handleDefaultValue}
                                onInputChange={handleInputChange}
                                onChange={handleOnChange}
                                ref={inputRef}
                                isClearable={true}
                                className={classNames(className, (error ? 'selectError' : ''))}
                                isDisabled={disabled}
                            />
                            {
                                error &&
                                <p className="mt-2 text-sm text-red-600 clear-both">{error}</p>
                            }
                        </Fragment>
                    )
                :
                (
                    <>
                        <ReactAsyncSelect
                            cacheOptions
                            loadOptions={loadOptions}
                            defaultOptions
                            placeholder={placeholder}
                            name={name}
                            classNamePrefix="react-select "
                            isMulti={multiple}
                            //menuIsOpen={true}
                            isClearable={true}
                            defaultValue={handleDefaultValue}
                            onInputChange={handleInputChange}
                            onChange={handleOnChange}
                            ref={inputRef}
                            className={classNames(className, (error ? 'selectError' : ''))}
                            isDisabled={disabled}
                        />
                        {
                            error &&
                            <p className="mt-2 text-sm text-red-600 clear-both">{error}</p>
                        }
                    </>
                )
            }

        </>
    );
}

export default AsyncSelect
