import Button from "../../../../ComponentPackage/Button";

const CustomTrustpoolPill = ({locum, active, setAction, disabled = false}) => {

    return (<div className="modal-splitters-inner float-left w-full flex justify-center relative">
	<div className="grid grid-cols-6 gap-3 mb-4">
        <div className="col-span-1">
            <Button onClick={e => !disabled ? setAction(locum, active) : null} type='button' style='primary' className='trustpool-btn btn-sm btn-icon' >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"/></svg>
            </Button>
        </div>
        <div className="col-span-2">
			<div className="float-left w-full block h-auto meta">
            <div className="w-full font-bold title">{locum.first_name} {locum.last_name}</div>
            <div className="w-full">{locum?.opl}</div>
			</div>
        </div>
        <div className="col-span-1">
            {
                 <div className="float-left w-full block h-auto meta">
                    <div className="w-full font-bold mb-1">W.Day</div>
                    <div className="w-full">£{locum.weekday}</div>
                </div>
            }
        </div>
        <div className="col-span-1">
            {
                 <div className="float-left w-full block h-auto meta">
                    <div className="w-full font-bold mb-1">Sat</div>
                    <div className="w-full">£{locum.saturday}</div>
                </div>
            }
        </div>
        <div className="col-span-1">
            {
                  <div className="float-left w-full block h-auto meta">
                    <div className="w-full font-bold mb-1">Sun</div>
                    <div className="w-full">£{locum.sunday}</div>
                </div>
            }
        </div>
    </div>
	</div>)
}

export {CustomTrustpoolPill as default}
