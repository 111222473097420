import {useState} from "react";
import TypeIcon from "./TypeIcon";
import {act} from "react-dom/test-utils";
import CustomTrustpool from "./CustomTrustpool";

const CustomTrustpoolIcon = ({active, icon, setCustomTrustpool, trustpoolLocums, disabled = false}) => {

    const [isOpen, setOpen] = useState(false)

    return (<>
        <TypeIcon type='custom_trustpool' label='Custom Trustpool' setType={type => setOpen(true)} active={active} icon={icon}/>
        <CustomTrustpool disabled={disabled} trustpoolLocums={trustpoolLocums} isOpen={isOpen} setOpen={setOpen} onSuccess={setCustomTrustpool}/>
    </>)
}

export {CustomTrustpoolIcon as default}
