import PendingPill from "../Components/PendingPill";
import {useState} from "react";
import RemoveLocumRequest from "../Components/RemoveLocumRequest";
import LocumRequest from "../Components/LocumRequest";
import DateFilter from "../Components/DateFilter";
import FilterDisplayPill from "../Components/FilterDisplayPill";
import useQueryString from "../../../../Hooks/useQueryString";
import FilterOption from "../Components/FilterOption";
import TablePaginationElement from "../../../../ComponentPackage/Tables/TablePaginationElement";

const Pending = ({locumRequests, store}) => {

    const [isDateFilterOpen, setDateFilterOpen] = useState(false)
    const [dateFilter, setDateFilter] = useQueryString("date", '')

    const [isEditModalOpen, setEditModalOpen] = useState(false)
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
    const [locumRequest, setLocumRequest] = useState({})

    const DateIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                          viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 inline-block">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"/>
    </svg>


    return (<>
        <div>
            <FilterOption setFilterOpen={setDateFilterOpen} label={<>Filter Date {DateIcon}</>} />
        </div>
        <div>
            {dateFilter && <FilterDisplayPill message={`Date: ${dateFilter}`} onRemove={e => setDateFilter(null)}/>}
        </div>
        {locumRequests?.data?.map(locumRequest => <PendingPill {...{
            locumRequest, setLocumRequest, setDeleteModalOpen, setEditModalOpen
        }} />)}
        { locumRequests?.meta?.pagination && <TablePaginationElement pagination={locumRequests?.meta?.pagination} /> }
        <RemoveLocumRequest locumRequest={locumRequest} setOpen={setDeleteModalOpen} isOpen={isDeleteModalOpen}/>
        <LocumRequest setLocumRequest={setLocumRequest} locumRequest={locumRequest} isOpen={isEditModalOpen}
                      setOpen={setEditModalOpen} store={store}/>
        <DateFilter setDate={setDateFilter} setOpen={setDateFilterOpen} isOpen={isDateFilterOpen} date={dateFilter}/>
    </>)
}

export {Pending as default}
