import React, {useRef, useState} from 'react';
import ReactSelect from 'react-select'
import Error from "../../ComponentPackage/Inputs/Error";

export default function Select({
                                   options = [],
                                   name,
                                   value = null,
                                   className,
                                   autoComplete,
                                   required,
                                   isFocused,
                                   error,
                                   placeholder = "",
                                   onChange,
                                   RenderIconLeft,
                                   valueKey = 'value',
                                   labelKey = 'label',
                                   disabled = false,
                                   innerRef
                               }) {

    const mappedOptions = options.map(element => ({...element, ...{value: element[valueKey], label: element[labelKey]}}))

    const handleDefaultValue = () => {

        if (Array.isArray(value)) {
            return value.map(item => {
                return {
                    value: item[valueKey],
                    label: item[labelKey]
                };
            })
        }

        if (value !== null && typeof value === 'object' && value.hasOwnProperty(valueKey) && value.hasOwnProperty(labelKey)) {

            return {
                value: value[valueKey],
                label: value[labelKey]
            };
        }
        if (value !== null && typeof value !== 'object') {
            return mappedOptions.filter(item => item.value === value);
        }

        return value;
    };

    const handleOnChange = (options, action) => {
        onChange(action.name, options.value, options)
    };

    const classes = error ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' :
        'react-select border-gray-300 focus:border-indigo-300 focus:ring-indigo-200';

    return (
        <>
            <ReactSelect options={mappedOptions} name={name} placeholder={placeholder}
                         defaultValue={handleDefaultValue}
                         onChange={handleOnChange}
                         required={required}
                         classNamePrefix="react-select "
                         className={
                             classes +
                             ` block w-full ` +
                             className +
                             (RenderIconLeft && ` pl-10 `)
                         }
                         isDisabled={disabled}
                         autoComplete={autoComplete}
                         isFocused={isFocused}
                         ref={innerRef}
            />
            {
                error &&
                <Error message={error} />
            }
        </>

    );
}
