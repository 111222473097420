import {useEffect, useState} from "react";
import {objMap} from "../../../../utilities";
import Checkbox from "../../../../ComponentPackage/Inputs/Checkbox";
import Field from "../../../../ComponentPackage/Inputs/Field";
import mobiscroll from '@mobiscroll/react';
import Datepicker from "../../../../ComponentPackage/Inputs/Datepicker";
import RateInput from "../../../../Components/Inputs/RateInput";
import {rateToObj} from "../../../Store/LocumRequests/Components/LocumRequest";

const DayRate = ({dayRate, day, setData, errors}) => {
    const [active, setActive] = useState(dayRate.active)
    const [rate, setRate] = useState(rateToObj(dayRate.rate))
    const [start, setStart] = useState(dayRate.start)
    const [finish, setFinish] = useState(dayRate.finish)
    const [breakTime, setBreakTime] = useState(dayRate.break_time)

    useEffect(() => {
        setData({
            ...{active, start, finish}, ...{rate: rate.value, break_time: breakTime}
        })
    }, [active, rate, start, finish, breakTime])

    return (<div className="grid grid-cols-1 table-splitter-inner">
        <div className="relative  table-splitter-block">
            <div className="w-full flex justify-start items-center content-center toggle-checkbox flex-wrap mb-3">

                <Checkbox name={`dayRates.${day}.active`} value={1} checked={active}
                          handleChange={(checked) => setActive(checked ? 1 : 0)}/>
                <span className="ml-2">{day}</span>
            </div>


            <div className="grid grid-cols-3 gap-3">
                <div className="grid-colspan-1">
                    <Field label="Rate" error={errors?.[`day_rates.${day}.rate`]}>
                        <RateInput onChange={(name, value, option) => setRate(option)} value={rate} disabled={!active}/>
                    </Field>
                </div>

                <div className="grid-colspan-1">
                    <Field label="Start">
                        <Datepicker type='time' value={start} onChange={(name, value) => setStart(value)}
                                    error={errors?.[`day_rates.${day}.start`]}
                                    name={`dayRates.${day}.start`} disabled={!active}/>
                    </Field>
                </div>


                <div className="grid-colspan-1">
                    <Field label="Finish">
                        <Datepicker type='time' value={finish} onChange={(name, value) => setFinish(value)}
                                    error={errors?.[`day_rates.${day}.finish`]}
                                    name={`dayRates.${day}.finish`} disabled={!active}/>
                    </Field>
                </div>

                <div className="grid-colspan-3">
                    <Field label="Allocated Break Time">
                        <Datepicker type='breakTime' value={breakTime} onChange={(name, value) => setBreakTime(value)}
                                    error={errors?.[`day_rates.${day}.break_time`]}
                                    name={`dayRates.${day}.break_time`} disabled={!active}/>
                    </Field>
                </div>


            </div>


        </div>

    </div>)
}

export {DayRate as default}
