import Button from "../../../../ComponentPackage/Button";
import moment from "moment";
import {useCallback} from "react";
import {Inertia} from "@inertiajs/inertia";
import route from "ziggy-js/src/js";


const PendingPill = ({locumRequest, setLocumRequest, setEditModalOpen, setDeleteModalOpen}) => {

    const openDeleteModal = useCallback(() => {
        setLocumRequest(locumRequest)
        setDeleteModalOpen(true)
    }, locumRequest)

    const openEditModal = useCallback(() => {
        setLocumRequest(locumRequest)
        setEditModalOpen(true)
    }, locumRequest)

    const redirectNegotiations = useCallback( () => {
        Inertia.visit(route('store.locumRequests.negotiations.index', locumRequest.id))
    }, locumRequest)

    return (
	<div className="w-full flex justify-center items-start content-start flex-wrap relative full-width-split-inner">
	<div className="grid grid-cols-6 gap-3 relative locum-pill w-full">

        <div className="col-span-2 ">
			<div className="w-full d-block locum-meta">
            <div className='w-full font-bold title'>{moment(locumRequest.date).format('ddd Do MMM YYYY')}</div>
            <div className='w-full'>{moment(locumRequest.start, 'HH:mm:ss').format('H:mm')} - {moment(locumRequest.finish, 'HH:mm:ss').format('H:mm')}</div>
			</div>
        </div>
        <div className="col-span-1">
			<div className="w-full d-block locum-meta">
            <div className="w-full font-bold">Rate</div>
            <div className="w-full">£{locumRequest.rate}</div>
			</div>
        </div>
        <div className="col-span-3">
			<div className="flex float-right">
                {locumRequest.has_active_negotiations && <div className="btn btn-sm btn-primary btn-icon " onClick={redirectNegotiations}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 7.1 5.8 12 12 12 2.4 0 4.9-.7 7.1-2.4L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zm16 352c0 8.8-7.2 16-16 16H288l-12.8 9.6L208 428v-60H64c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16h384c8.8 0 16 7.2 16 16v288zm-96-216H144c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h224c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm-96 96H144c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16z"/></svg>
				</div>}

				<div className="btn btn-sm btn-primary btn-icon ml-2" onClick={openEditModal}>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M493.255 56.236l-37.49-37.49c-24.993-24.993-65.515-24.994-90.51 0L12.838 371.162.151 485.346c-1.698 15.286 11.22 28.203 26.504 26.504l114.184-12.687 352.417-352.417c24.992-24.994 24.992-65.517-.001-90.51zM164.686 347.313c6.249 6.249 16.379 6.248 22.627 0L368 166.627l30.059 30.059L174 420.745V386h-48v-48H91.255l224.059-224.059L345.373 144 164.686 324.687c-6.249 6.248-6.249 16.378 0 22.626zm-38.539 121.285l-58.995 6.555-30.305-30.305 6.555-58.995L63.255 366H98v48h48v34.745l-19.853 19.853zm344.48-344.48l-49.941 49.941-82.745-82.745 49.941-49.941c12.505-12.505 32.748-12.507 45.255 0l37.49 37.49c12.506 12.506 12.507 32.747 0 45.255z"/></svg>
				</div>

				<div className="btn btn-sm btn-danger btn-icon ml-2" onClick={openDeleteModal}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M296 432h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zm-160 0h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zM440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h24a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zM384 464a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320zm-168-32h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8z"/></svg>
				</div>
			</div>
        </div>
    </div>
	</div>)
}

export {PendingPill as default}
