import React from "react";
import Field from "../../../ComponentPackage/Inputs/Field";

export default function Form({data, setData, errors, children, user}) {

    function generatePassword(e) {
        e.preventDefault()

        setData('password', Math.random().toString(36).substr(2, 8))
    }

    return (<>

            <div className="grid grid-cols-6 gap-5">
                <div className="col-span-6 md:col-span-3">
                    <Field label="Name *" name="name" value={data.name} onChange={setData}
                           error={errors.name} />
                </div>
                <div className="col-span-6 md:col-span-3">
                    <Field label="Email *" name="email" value={data.email} onChange={setData}
                           error={errors.email}/>
                </div>
                <div className="col-span-6 md:col-span-3">
                    <Field label={`Password${!user ? '*' : ''}`} name="password" value={data.password} onChange={setData}
                           error={errors.password}/>
                </div>

                {children}

            </div>


        </>)
}
