import React from 'react';

export default function Checkbox({ name, value, handleChange, checked, disabled,
     className = 'rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50' }) {


    const onChange = (event) => {
        if (event.target.checked) {
            handleChange(true, event.target.value, event);
        } else {
            handleChange(false, event.target.value, event)
        }
    }

    return (
		<div className="checkbox">
        <input
            type="checkbox"
            name={name}
            value={value}
            disabled={disabled}
            defaultChecked={checked}
            className={className}
            onChange={onChange}
        />
		<span></span>
		</div>
    );
}
