/**
 * Returns a string of class names from the given arguments, excluding any false values.
 *
 * @param classes
 * @returns {string}
 */
export const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
};

export const setDataByKey = (key, value, data) => {
    if (typeof key === 'string'){
        return setDataByKey(data, key.split('.'), value);
    }else if (key.length === 1 && value !== undefined){
        return data[key[0]] = value;
    }else if (key.length === 0){
        return data;
    }else{
        return setDataByKey(data[key[0]], key.slice(1), value);
    }
};

export const groupBy = (x,f)=>x.reduce((a,b)=>((a[f(b)]||=[]).push(b),a),{});

export const objMap = (obj, func) => {
    return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, func(v)]));
}

export const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item,
        };
    }, initialValue);
};
