import React, {useEffect, useState} from 'react';
import {Head, Link, useForm} from '@inertiajs/inertia-react';
import Guest from "../../Layouts/Guest";
import Label from "../../../../ComponentPackage/Inputs/Label";
import Input from "../../../../ComponentPackage/Inputs/Input";
import Checkbox from "../../../../ComponentPackage/Inputs/Checkbox";
import Button from "../../../../ComponentPackage/Button";
import Field from "../../../../ComponentPackage/Inputs/Field";
import AsyncSelect from "../../../../ComponentPackage/Selects/AsyncSelect";
import {Section} from "../../../../ComponentPackage/Sections";
import {default as StoreForm} from "../../../Admin/Stores/Form";
import Datepicker from "../../../../ComponentPackage/Inputs/Datepicker";
import Contact from "./Contact";

function InputError(props) {
    return null;
}

const Register = ({status, canResetPassword}) => {
    const {data, setData, post, processing, errors, reset} = useForm({});

    const [store, setStore] = useState({})
    const [user, setUser] = useState({})
    const [clinicStart, setClinicStart] = useState()
    const [clinicFinish, setClinicFinish] = useState()
    const [breakTime, setBreakTime] = useState()

    useEffect(() => {
        setData({
            store : {   ...store,
                        ...{
                            start: clinicStart,
                            finish: clinicFinish,
                            break_time: breakTime
                        }
            },
            user: user
        })
    }, [store, user, clinicStart, clinicFinish])

    const onHandleChange = (event) => {
        setData(event.target.name, event.target.type === 'checkbox' ? event.target.checked : event.target.value);
    };

    const submit = (e) => {
        e.preventDefault();

        post(route('store.register'));
    };

    return (<Guest>
        <Section header={{title: "Sign Up for an account"}}>
            <div className="float-left w-full block px-2">

			<StoreForm setData={setStore} errors={errors} isRegister={true}>

                <div className="float-left w-full block mb-5">
                    <Field label="What time does your clinic start?*" error={errors?.['store.start']}>
                        <Datepicker type='timegrid' name='clinic_start' value={clinicStart}
                                    onChange={(name, value) => setClinicStart(value)} />
                    </Field>
                </div>
                <div className="float-left w-full block mb-5">
                    <Field label="What time does your clinic finish?*" error={errors?.['store.finish']}>
                        <Datepicker type='timegrid' name='clinic_finish' value={clinicFinish}
                                    onChange={(name, value) => setClinicFinish(value)} />
                    </Field>
                </div>

                <div className="float-left w-full block">
                    <Field label="Allocated Break Time *">
                        <Datepicker type='breakTime' value={breakTime} onChange={(name, value) => setBreakTime(value)}
                                    error={errors?.['store.break_time']}
                                    name={`break_time`} />
                    </Field>
                </div>
            </StoreForm>

            <Contact errors={errors} setData={setUser} />


            <div className="grid gap-8 float-left clear-both w-full">
                <div>
                    <div
                        className="float-left drop-shadow-md box-shadow unlimited-access w-full d-flex flex-wrap justify-content-center p-5 ">
                        <div
                            className="title  border-radius-50 price float-left w-auto d-flex align-items-center mixed-gradient">
                            Make unlimited locum bookings for just <strong>£99.00</strong> per month
                        </div>

                        <div className="my-5 float-left text-center w-full">
                            <p>For a small monthly fee, make unlimited locum booking requests, 24 hours
                                a day, from your phone, tablet or desktop.</p>
                        </div>

                        <h4 className="float-left w-full text-center mb-4">Why choose Locums Direct?</h4>

                        <div className="grid grid-cols-2 gap-4 float-left w-full mb-4">
                            <div >
                                <strong>UNLIMITED</strong> – each registered store can make unlimited
                                locum requests to meet your business needs.
                            </div>

                            <div >
                                <strong>AUTOMATION</strong> – our innovative locum booking platform is
                                fully automated, giving you total convenience.
                            </div>

                            <div >
                                <strong>INCREASE SAVINGS</strong> – compared to current locum agencies,
                                Locums Direct should prove to be an effective money saving solution.

                            </div>
                            <div >
                                <strong> TRUSTPOOL - </strong>
                                Use our unique TRUSTPOOL feature and
                                automatically send all your locum requirements to your most trusted
                                locums for first refusal. Ensuring you get the locum you want every
                                time.
                            </div>
                        </div>

                        <h4 className="float-left w-full text-center">Sign up now and grow your business
                            starting today!</h4>

                    </div>
                </div>
            </div>
            <div className="grid gap-5 grid-col-1 float-left clear-both w-full">
                <div className="flex items-center justify-center mt-1 mb-6">
                    <Button className="ml-4" processing={processing} onClick={submit}>
                        Continue to Payment
                    </Button>
                </div>
            </div>
			</div>
        </Section>
    </Guest>);
}

export {Register as default}
