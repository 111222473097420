import React from 'react';
import Label from "../../ComponentPackage/Inputs/Label";
import Input from "../../ComponentPackage/Inputs/Input";
import Error from "../../ComponentPackage/Inputs/Error";

const Field = ({ type = "text", label, name, options = [], value, onChange, isFocused = false, error, className, children, disabled = false }) => {

    const handleChange = e => {

        if(e.target.type === "radio" && e.target.checked === false) {
            return;
        }

        if(e.target.value.length > 0 && e.target.type === "radio") {
            onChange(name, isNaN(e.target.value) ? e.target.value : parseFloat(e.target.value));
        } else {
            onChange(name, e.target.value);
        }
    };

    return (
        <div className="float-left w-full relative flex justify-content-start flex-wrap content-center items-center fields ">
		
            <Label className="w-full title block" forInput={name} value={label} />

            {children ?? <Input
                type={type}
                name={name}
                value={value}
                className=""
                autoComplete={name}
                isFocused={isFocused}
                onChange={handleChange}
                disabled={disabled}
                error={error}
            />}
			
			

            {
                children && error && error.length > 0 && <Error message={error} />
            }
        </div>
    );
}

export default Field;
