import Datepicker from "../../../../ComponentPackage/Inputs/Datepicker";
import Field from "../../../../ComponentPackage/Inputs/Field";
import {useCallback, useEffect, useState} from "react";
import Button from "../../../../ComponentPackage/Button";
import RateInput from "../../../../Components/Inputs/RateInput";
import moment from "moment";
import {rateToObj} from "./LocumRequest";


const formatLocumRequest = (locumRequest) => {
    return {
        date: moment(locumRequest?.date).format('DD/MM/YYYY'),
        rate: locumRequest?.rate,
        start: locumRequest?.start,
        finish: locumRequest?.finish,
    }
}

const RequestDate = ({requestDate, removeDate, setData, canDelete, disabled = false}) => {

    const [date, setDate] = useState(requestDate?.date)
    const [startTime, setStartTime] = useState(requestDate?.start)
    const [finishTime, setFinishTime] = useState(requestDate?.finish)
    const [rate, setRate] = useState(requestDate ? rateToObj(requestDate.rate) : null)

    useEffect(() => {

        setData({
            date: date,
            start: startTime,
            finish: finishTime,
            rate: rate?.value,
        })
    }, [date, startTime, finishTime, rate])

    return (<div className="modal-splitters-inner float-left w-full flex justify-center relative">
        <div className="grid grid-cols-3 gap-3">
            <div className='col-span-3'>
				<div className="flex content-end items-end">
					<Datepicker type='date' name='date' value={date}
								min={moment().toDate()}
								disabled={disabled}
								onChange={(name, value) => setDate(value)} />
			   
				{canDelete && <Button style='danger' type='button' className="btn-icon ml-3" onClick={removeDate}>
					
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M296 432h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zm-160 0h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zM440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h24a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zM384 464a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320zm-168-32h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8z"/></svg>
					
					
				</Button>}
				</div>
         	</div>
            <div className="col-span-1">
                <Datepicker type='timegrid' name='start' value={startTime}
                            disabled={disabled}
                            onChange={(name, value) => setStartTime(value)} />
            </div>
            <div className="col-span-1">
                <Datepicker type='timegrid' name='finish' value={finishTime}
                            disabled={disabled}
                            onChange={(name, value) => setFinishTime(value)} />
            </div>
            <div className="col-span-1">
                <RateInput disabled={disabled} onChange={(name, value, option) => setRate(option)} value={rate} />
            </div>

        </div>
    </div>)
}

export {RequestDate as default, formatLocumRequest}
