import React from 'react';
import {Inertia} from "@inertiajs/inertia";
import Button from "../Button";

const TablePaginationElement = ({ pagination }) => {

    const handleClickPrevious = e => Inertia.visit(pagination.links.previous);
    const handleClickNext = e => Inertia.visit(pagination.links.next);

    let number_to, number_from;
    if(pagination.current_page === pagination.total_pages) {
        number_to = pagination.total;
        number_from = pagination.total - (pagination.count - 1);
    } else {
        number_to = (pagination.count * pagination.current_page);
        number_from = number_to - (pagination.count - 1);
    }

    return (
        <nav
            className="mt-6 flex items-center justify-between content-center items-center"
            aria-label="Pagination"
        >
            <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{ number_from }</span> to <span className="font-medium">{ number_to }</span> of{' '}
                    <span className="font-medium">{ pagination.total }</span> results
                </p>
            </div>
            <div className="flex-1 flex justify-between sm:justify-end">
                <Button
                    type="button"
                    className="ml-3"
                    disabled={!pagination.links.previous}
                    onClick={handleClickPrevious}
                >Previous</Button>

                <Button
                    type="button"
                    className="ml-3"
                    disabled={!pagination.links.next}
                    onClick={handleClickNext}
                >Next</Button>

            </div>
        </nav>
    );
}

export default TablePaginationElement;
