import Authenticated from "../Layouts/Authenticated";
import PageTitle from "../../../ComponentPackage/PageTitle";
import {Section} from "../../../ComponentPackage/Sections";
import Field from "../../../ComponentPackage/Inputs/Field";
import {useCallback, useState} from "react";
import {Inertia} from "@inertiajs/inertia";
import Button from "../../../ComponentPackage/Button";

const Review = ({}) => {
    const [errors, setErrors] = useState()
    const [processing, setProcessing] = useState()

    const [rating, setRating] = useState()
    const [title, setTitle] = useState()
    const [feedback, setFeedback] = useState()

    const onSave = useCallback(() => {
        Inertia.post(route('store.settings.review.store'), {rating, title, feedback}, {
            onError: errors => {
                setErrors(errors)
                setProcessing(false)
            },
            onSuccess: e => setProcessing(false),
            onBefore: e => setProcessing(true)
        })
    }, [rating, title, feedback])

    return <>
         <Section className="clear-both float-left w-full block">
			<div className="grid grid-cols-1 gap-5">
				<div className="col-span-1">
					<Field label='Select rating *' error={errors?.rating}>
                <div className='rating-selector flex justify-start items-center w-full float-left'>
                    {[...Array(5)].map((x, i) =>
						<svg onClick={e => setRating(i+1)}
                             fill={`${i < rating ? 'gold' : 'none'}`}
                             key={i} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 245">
<path d="m56,237 74-228 74,228L10,96h240"/>
</svg>
					
                       
                    )}
                </div>
            </Field>
				</div>
				
				
				<div className="col-span-1">
					 <Field label='Feedback Title *' error={errors?.title}
                   onChange={(name, value) => setTitle(value)}
                   value={title}
            />
				</div>
				
				<div className="col-span-1">
					 <Field label='Feedback Title *' error={errors?.feedback} type='textarea'
                   onChange={(name, value) => setFeedback(value)} value={feedback}/>

				</div>
				
				
				<div className="col-span-1">
					            <div className="float-right  mt-1">
                <Button style="positive" processing={processing} onClick={onSave}>
                    Send Feedback
                </Button>
            </div>
				</div>
			</div>


        </Section>
    </>
}

Review.layout = page => <Authenticated
    children={page}
    auth={page.props.auth}
    header={<PageTitle title='Leave Review'/>}
/>

export {Review as default}
